import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion'
import './styles/noticias.css'
import img1 from '../Components/assets/Mersinho-Notícias-1.webp'
import img2 from '../Components/assets/IMG-NOTÍCIAS.webp'
import img3 from '../Components/assets/É-MAIS-PROGRESSO-2.webp'
import logonoticias from './assets/Logo-conecta-mersinho-ícone-gradiente-6.webp'

const images = [img1, img2, img3]


function Noticias() {
    const carousel = useRef()
    const [width, setWidth] = useState(0)

    useEffect(() => {
        console.log(carousel.current?.scrollWidth, carousel.current?.offsetWidth)
        setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])
    

    return (
    <section id="noticias">
        <div className="noticias-title">
            <h1>Notícias</h1>
        </div>
        <div id="logo-noticias">
            <img src={logonoticias} alt="logomersinho" />
        </div>
        <motion.div ref = {carousel} className="carrossel" whileTap={{cursor: "grabbing"}}>
            <motion.div className='inner-carrossel' drag = "x"
            dragConstraints={{left: -width, right: 0}}
            initial={{x:0}}
            >
                {images.map(image => (
                    
                    <motion.div className='item' key={image}>
                        <img src={image} alt="Imagem de notícia"/>
                    </motion.div>
                    ))}

            </motion.div>
        </motion.div>
        <div>
            <button className='button-confira' href="#">SAIBA MAIS</button>
        </div>
    </section>

    );
};

export default Noticias;