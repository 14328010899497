import React from 'react';
import { GridList } from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import CardList from '../../../components/CardList/CardList';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    }
}));

export const UserList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {
    }

    const columns = [
        // { name: 'id', title: '#' },        
        { name: 'name', title: 'Nome', filterable: true, primary: true },
        { name: 'wifename', title: 'wifename', width: 150, filterable: true, secondary: true },
        { name: 'phone', title: 'Fone', width: 150 },
        { name: 'email', title: 'e-mail', filterable: true, content: true },
        { name: 'cpf', title: 'CPF', width: 1, filterable: false, avatar: true },
    ]


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                        <CardHeader title="Usuários" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3} wrap="wrap">
                                {isMobile ?
                                    <CardList route={"users"} endpoint={"users"} columns={columns} /> :

                                    <GridList modelName={"Usuário"}
                                        editModelRoute={'/users'}
                                        newModelRoute={'/users/new'}
                                        deleteCallback={deleteCallback}
                                        deleteCallbackFailure={deleteCallbackFailure}
                                        endpoint={'users'}
                                        // tableWidth={900}
                                        enableActions={true}
                                        columns={[
                                            { name: 'phone', title: 'Fone', width: 150 },
                                            { name: 'name', title: 'Nome', width: 200 },
                                            { name: 'profissao', title: 'Profissão', width: 150 },
                                            { name: 'statusProfissional', title: 'Sit. Proffisional', width: 180 },
                                            { name: 'wifename', title: 'Cônjuge', width: 150 },
                                            { name: 'email', title: 'e-mail' }
                                        ]} />}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

