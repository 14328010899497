import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Link } from '@material-ui/core';
import Icon from '../../../../components/icon/icons';
import background from "./topbar.png";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  signInButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
      
      style = {{
          marginTop: 20, 
          marginLeft:50, 
          height:40, 
          backgroundImage: `url(${background})`, 
          //backgroundRepeat: 'repeat-x'
        }}
    > 

    <Toolbar style = {{marginTop: 0, marginLeft:'2%'}}>
        <RouterLink to="/" >
          <img
            alt="Logo"
            src="/images/logos/_FatorPolitico.png"
            width="150" 
            height="72"            
          />
        </RouterLink>
        <div className={classes.flexGrow} />

        <Link href='https://www.youtube.com' style = {{marginTop: -24, marginLeft: 20}}>
        <Icon 
          icon='youtube' 
          color= '#000'
          size= '24' 
           />
        </Link>

        <Link href='https://www.facebook.com' style = {{marginTop: -24, marginLeft: 20}}>
        <Icon 
          icon='facebook2' 
          color= '#000'
          size= '20' 
           />
        </Link>

        <Link href='https://www.instagram.com' style = {{marginTop: -24, marginLeft: 20}}>
        <Icon 
          icon='instagram' 
          color= '#000'
          size= '20' 
           />
        </Link>

        <Link href='https://twitter.com' style = {{marginTop: -24, marginLeft: 20, marginRight:0}}>
        <Icon 
          icon='twitter' 
          color= '#000'
          size= '20' 
           />
        </Link>      

      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {  
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
