import React, {useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { api } from '../../../services';
import { Card, CardHeader, CardContent, Divider, Grid, Button, TextField } from '@material-ui/core';
import { renderTextField, renderDatePicker } from '../../../components/Fields/Fields';
import { ToastMessage } from '../../../layouts';
import useStyles from './FormStyle';
import ReCAPTCHA from 'react-google-recaptcha';
import { DateMaskDDMMYYYY } from '../../../services/masks';
import { isMobile } from "react-device-detect";

const formName = 'Resultado';

const validate = (values, props) => {
    const errors = {}

    const requiredFields = [
        'requisicao',
        'dataNascimento'
    ]    

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    // VALIDAÇAO DATA DE NASCIMENTO
    
    if (
        values.dataNascimento &&
        !/^([0-2][0-9]|(3)[0-1])(((0)[0-9])|((1)[0-2]))\d{4}$/i.test(values.dataNascimento)
    ) {
        errors.dataNascimento = 'Valor de data invalido'
    }

    return errors;
}

let Form = (props) => {
    const { handleSubmit, valid, reset } = props;
    const [result, setResult] = useState('');
    const [recaptched, setRecaptched] = useState(false);
    const upper = value => value && value.toUpperCase();
    const classes = useStyles();

    const onSubmit = values => {
        api.get(`/public/exames/${values.requisicao}/${values.dataNascimento}`)
            .then(res => {
                setResult(res.data);
            })
            .catch((err) => {
                ToastMessage.error("Resultado não encontrado");
            });
    }

    const resetForm = () => {
        reset();
        setResult('');
        setRecaptched(false);
    }

    const downloadTestResult = (e) => {
        e.preventDefault();
        api.get(`/public/exames/${result.requisicao}/${formatDate(result.dataNascimento)}/testResult`)
        .then(res => {
            window.open(res.data.url, "_blank")
        }).catch(error => {
            ToastMessage.error("Não há resultado do Laboratório responsável para esta Requisição");
        })
    }

    const formatDate = (isoDate) => {
        const year = isoDate.substring(0, 4);
        const month = isoDate.substring(5, 7);
        const day = isoDate.substring(8, 10);

        return day + month + year;
    }

    const onChange = (value) => {
        setRecaptched(true);
    }

    const onExpired = (value) => {
        setRecaptched(false);
    }

    return (
        <>
        <div className={classes.mainForm}>
        <br></br>        
        <form onSubmit={handleSubmit(onSubmit)} >        
        {/* <Card className='cardPersonalData'> */}
        <Grid container spacing={2} wrap="wrap" className={classes.container} >
            <Grid item xs={2} >
                        
            </Grid> 

            
                <Grid item xs={8} className={classes.mainRigth} >
                                        
                        {
                            !result && 
                            <>
                                <Grid item xs={12} >
                                    <CardHeader title="Informações da Requisição do Exame" />
                                    <Divider style={{ marginBottom: 15, height: 3 }}></Divider>
                                </Grid>
                                
                                <Grid item xs={12} >
                                    <Field name="requisicao" type="text" component={renderTextField} label="Número da Requisição" normalize={upper} style={{maxWidth:'100%', width: 300, marginTop:15 }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="dataNascimento"  pattern="\d*" inputmode="numeric" type="tel" label="Data de nascimento" component={renderDatePicker} {...DateMaskDDMMYYYY} style={{maxWidth: '100%', width: 300, marginTop:15}} />
                                </Grid>                                

                                <Grid xs={12} >
                                    <Card style={{margin: '0 auto', maxWidth: '100%', width: 300, marginTop:15}}>
                                    
                                    <ReCAPTCHA
                                        sitekey={process.env.SITE_KEY || "6LfqBaMaAAAAAF_GDt8y8EBxjZYIJiLfYsludp2j"}
                                        onChange={onChange}
                                        onExpired={onExpired}
                                        className={classes.recaptchabox}
                                    />
                                    </Card>
                                </Grid>
                            </>
                        }

                        {
                            result &&
                            <>
                            <Card className="personalData">
                                <CardHeader title="Resultado" />
                                <Divider />
                                <CardContent> 

                                        <Grid item xs={12} >
                                            <TextField  variant="outlined" type="text" style={{width: 300, maxWidth: "100%"}} label="Requisição" disabled={true} value={result.requisicao} />                                        
                                       
                                            {isMobile? <TextField variant="outlined"  type="text" style={{width: 300, marginTop:15, maxWidth: "100%"}} label="Situação" disabled={true} value={result.statusExame} />:
                                                       <TextField variant="outlined"  type="text" style={{width: 300, marginLeft:10, maxWidth: "100%"}} label="Situação" disabled={true} value={result.statusExame} />}                                                
                                        </Grid>  

                                        <Grid item xs={12} >    
                                            <TextField variant="outlined"  type="text" style={{width: 610,marginTop:15, marginBottom: 15, maxWidth: "100%"}} label="Nome" disabled={true} value={result.paciente} />
                                        </Grid>   
                                        <Grid item xs={12} >
                                            <Divider style={{ marginBottom: 15, height: 3 }}></Divider>
                                        </Grid>

                                        {
                                            (result.statusExame === 'Resultado Liberado') &&
                                            <Grid item xs={12} >
                                                <a href={`${api.getApiURL()}/public/exames/${result.requisicao}/${formatDate(result.dataNascimento)}/pdf`} target = "_blank" rel="noopener noreferrer">
                                                    <Button variant="contained" style={{width: 300, marginTop: 15, maxWidth: "100%"}}>Baixar resultado em PDF</Button>
                                                </a>
                                            </Grid>
                                        }

                                        {
                                            result.possuiResultadoLaboratorio && 
                                            <Grid item xs={12} >
                                                <Button variant="contained" style={{width: 300, marginTop: 15, maxWidth: "100%"}} onClick={downloadTestResult}>Laudo do Laboratório Responsável</Button>
                                            </Grid>
                                        }

                                </CardContent>
                            </Card>
                            </>
                        }

                    {/* <Divider /> */}

                    <Grid item xs={12} >
                        {/* <Card> */}
                            {/* <CardActions style={{ margin: "1rem" }}>                                                     */}
                                { !result && recaptched && <Button type="submit" color="primary" variant="contained" disabled={!valid} style={{width: 250, maxWidth: "100%", marginTop:15, marginBottom:40}} > CONSULTAR </Button> }
                            {/* </CardActions> */}
                        {/* </Card> */}
                    </Grid>                       

                    <Grid item xs={12} >
                        {/* <Card> */}
                            {/* <CardActions style={{ margin: "1rem" }}>                                                     */}
                                { result && <Button type="button" color="primary" variant="contained" onClick={resetForm} style={{width: 250, maxWidth: "100%", marginTop:15, marginBottom:40 }} > LIMPAR </Button> }                            
                            {/* </CardActions> */}
                        {/* </Card> */}
                    </Grid>                      

                </Grid>

            
        </Grid>
        </form>
        </div>
        </>
    );
};

Form = reduxForm({
    form: formName,
    validate
})(Form);

export default Form;

