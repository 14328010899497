import React, {useState} from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { validCpf } from '../../../services';
import api from '../../../services/api';
import apiCEP from '../../../services/apiCEP';
import { Grid,  Avatar, Button, IconButton, } from '@material-ui/core';
import { renderTextField, renderSelectField, renderCheckbox, renderSwitch } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import MyDivider from '../../../components/MyDivider';
import { cpfMask, phoneMask, cepMask } from '../../../services/masks';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { useParams } from "react-router-dom";
import AuthState from '../../../auth/Auth';
import QRCode from "react-qr-code";
import Photo from './Photo';
import LOCAL_URL from '../../../services/qrCodeConfig';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { isMobile } from 'react-device-detect';
import Box from '@mui/material/Box';
import Icon from '../../../components/icon/icons';
import { useHistory } from 'react-router';
import UploadFile from '../../../components/UploadFile';

const formName = 'User';
const modelName = 'users';
const label = 'Usuário';

// const useStyles = theme => ({
//     root: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         minHeight: 'fit-content'
//     },
//     avatar: {
//         width: 60,
//         height: 60
//     },
//     brQrCode:{
//         bgColor:'#09f3ad'
//     }
// });

const validate = (values, props) => {
    const errors = {}
    
    const requiredFields = [        
        'phone',
        'name',
        'cpf',
        'birthDate',
        'email',
        'valpassword',
        'confpassword',
    ]

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    if ((values['valpassword'] !== '000000000') || (values['confpassword'] !== '000000000')) {
        if (values['valpassword'] !== values['confpassword']){
            errors['valpassword'] = 'Senha diferente';
        } else {
            props.dispatch(change(formName, 'password', values['valpassword']));
        }

    }


    if (
        values['email'] &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Endereço de Email invalido'
    }       
    
    if (values['cpf'] && !validCpf(values['cpf'])) {
        errors.cpf = 'CPF invalido';
    }


    return errors;
}

let Form = (props) => {
    const {reset} = props;
    const upper = value => value && value.toUpperCase()
    const lower = value => value && value.toLowerCase()
    const { id } = useParams();
    const [entity, setEntity] = useState({});
    const isAdmin = (AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false;
    const [user, setUser] = useState({});    
    // const [uploading, setUploading] = useState(false);

    const [center, setCenter] = useState({ lat: -7.111588, lng: -34.850261 });
    const [position, setPosition] = useState({});
    
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [loadingUserProfile, setLoadingUserProfile] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [photoUrl, setPhotoUrl] = useState("");
    const [profileColor, setProfileColor] = useState("");

    const [loadingPhotoUrl, setLoadingPhotoUrl] = useState(false);

    const [loadingResponsavel, setLoadingResponsavel] = useState(false);
    const [responsavelList, setResponsavelList] = useState([]);  
    const [formCompleto, setFormCompleto] = useState(false);
    
    
    const history = useHistory();

    // const { classes, rest, className } = props
    // const {user, profile, company} = state;

    const updateFormProps = (result) => {
        const data = result.data;
        Object.keys(data).forEach((field) => {
            props.dispatch(change(formName, field, data[field]));
        });
    }

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={12}
        center={center}
        defaultCenter={{ lat: -7.111588, lng: -34.850261 }}
    >
        {position && position.lat &&                 
            <Marker 
                icon={{url:'/images/MarkerPerson.png' } }
                position={position}  />   
        }

    </GoogleMap>
))

    const requestCpf = (cpf) => {
        const isValid = validCpf(cpf);
        if (isValid) {
            api.get(`${modelName}/cpf/${cpf}`).then(entity => {
                updateFormProps(entity);
                props.dispatch(change(formName, 'submit_type', 'update'));
            }).catch(err => {
            });
        } else {
            reset();
        }
    }

    const clearOnEnterPassword = (event) => {        
        if ((event.target.value === '000000000') && (!id)){
            props.dispatch(change(formName, 'valpassword', ''));
            props.dispatch(change(formName, 'confpassword', ''));
        }    
    }

    const getDataByCEP = (event) => {

        let cep = event.target.value;

        apiCEP.get(`/ws/${cep}/json`).then(
            (data) => {
                props.dispatch(change(formName, 'address', data.data.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'neighborhood', data.data.bairro.toUpperCase()));
                props.dispatch(change(formName, 'city', data.data.localidade.toUpperCase()));
                props.dispatch(change(formName, 'state', data.data.uf.toUpperCase()));
                props.dispatch(change(formName, 'complement', data.data.complemento.toUpperCase()));

            }
        ).catch(err => {
        });       
        
    };

    const afterLoadEntity = (entity) => {     
        AuthState.getUserInfo().then( (luser) => setUser(luser));        
        getPhotoUrl(entity);
        setEntity(entity);
        getUserProfile(entity);
        setPosition({lat: parseFloat(entity.latitude), lng: parseFloat(entity.longitude)});

    }  

    const getPhotoUrl = (entity)  => {
        if (!loadingPhotoUrl) {
            api.get(`/users/photourl/${entity.cpf}`)
            .then((response) => {
                setLoadingPhotoUrl(true);
                const preSignedURL = response.data.url
                setPhotoUrl(preSignedURL);
    
            })
            .catch((error) => {
                console.error(error);            
                // ToastMessage.error('Problema ao atualizar Perfil');
            })
        }

    }

    const handleGeoLocation = () => {
        navigator.geolocation.getCurrentPosition(success, error, options); 
      }  
    
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      
      function success(pos) {
        var crd = pos.coords;
                     
        props.dispatch(change(formName, 'latitude', crd.latitude));
        props.dispatch(change(formName, 'longitude', crd.longitude));
        
        setPosition({lat: crd.latitude, lng: crd.longitude});
        setCenter({lat: crd.latitude, lng: crd.longitude});

      };
      
      function error(err) {        
        console.warn('ERROR(' + err.code + '): ' + err.message);
      };   
      
    const getProfileList = () => {
      if (!loadingProfile) {
          setLoadingProfile(true);
          api.get('profiles?pageSize=-1').then(list => {
            (isAdmin?setProfileList(list.data.rows):setProfileList(list.data.rows.filter((row)=> row.name !=='ADMIN')))
            
         });
      }
    }

    const getUserProfile = (entity)  => {
        if (!loadingUserProfile){
            setLoadingUserProfile(true);
            api.get(`/users/profile/${entity.id}`)
            .then((response) => {
                props.dispatch(change(formName, 'profileId', response.data.profileId));                
                setProfileColor(response.data.profile.name);
                
                
                switch (response.data.profile.name) {
                    case 'ADMIN':
                        setProfileColor('#9dff80');                      
                    break;
                    case 'LIDERANCA':
                        switch (entity.cargo) {
                            case 'A':
                                setProfileColor('#65b4ff');                      
                            break;
                            case 'V':
                                setProfileColor('#70d4e6');
                            break;
                            case 'E':
                                setProfileColor('#f78585');
                            break;
                            case 'F':
                                setProfileColor('#9a6af5');
                            break; 
                            case 'S':
                                setProfileColor('#9b499b');
                            break; 
                            case 'P':
                                setProfileColor('#237c57');
                            break; 
                            case 'G':
                                setProfileColor('#e4e27c');
                            break;                                                                                                                                                                 
                            default:
                                setProfileColor('#b6b6b6');
                          }                                            
                    break;
                    case 'MULTIPLICADOR':
                        setProfileColor('#f79e76');
                    break;
                    case 'CONTATO':
                        setProfileColor('#b6b6b6');
                    break;                      
                    default:
                        setProfileColor('#b6b6b6');
                  }
                  
    
            })
            .catch((error) => {
                console.error(error);            
                // ToastMessage.error('Problema ao atualizar Perfil');
            })
        }
    }

    const getResponsaveisList = () => {

        if (!loadingResponsavel) {
            setLoadingResponsavel(true);
            api.get('/users/responsavel?pageSize=-1').then(list => {

                let result = [];

                list.data.rows.forEach((item) => {
                    result.push(item.user)
                });
                setResponsavelList(result);                
            });
        }
    }     
    
    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.name}</option>);
            })
            return (finalList);
        }
    }   

    const handlerClickEvento = (e) => {
        history.push(`/registroAtividadesCfg/${id}/evento`);
    }

    const clickFormCompleto = (e) => {     
        setFormCompleto(e.target.checked);

    }  

    getProfileList();
    getResponsaveisList();


    return (
        
        <BasicForm 
            hiddenNewButton={isAdmin?false:true} 
            name={'User'}
            customAfterLoadEntity={afterLoadEntity}
            routeAfterSubmit={isAdmin?'/users/':`/home`} 
            content={
                <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={6} >
                        <Box color="inherit" sx={{ mr: 1 }} style={{width: 132 ,backgroundColor:profileColor}} >
                            <Avatar
                                alt="Person"                            
                                style={{marginLeft:'2', width: 130,height: 130, maxWidth: '100%',maxHeight: '100%'}}                            
                                src={photoUrl || "/images/avatars/generic-user.png"}
                            />  
                        </Box>
                    </Grid>

                    <Grid item xs={6} >                        
                        <QRCode
                            // alt="Person"                            
                            style={{marginLeft:'1%', width: 130,height: 130, maxWidth: '100%',maxHeight: '100%'}} 
                            bgColor='#f5f5f5'
                            fgColor='#000000'
                            size={130}
                            //value={LOCAL_URL+"/crachavirtual/" + entity.id}
                            value={`${LOCAL_URL}/${entity.id}/regusuario/`}
                        />                           
                    </Grid>

                    {entity.id &&
                    <Grid item xs={12} >
                        <IconButton aria-label="settings" label="(+)Evento" onClick={handlerClickEvento}>
                            <Icon icon='payment' size='36' style={{height:100}} />
                            
                        </IconButton> 
                        (+)Evento  
                                            
                    </Grid>       
                    }             

                    <Grid item xs={12} >
                        <Field name="cpf" {...{disabled: entity.id, required: true }} type="text" component={renderTextField} label="CPF" normalize={upper} style={{ maxWidth: '100%', width: 300 }} maxLength={11} {...cpfMask(requestCpf)} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="name" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="phone" type="text" component={renderTextField} label="Telefone" normalize={upper} style={{ maxWidth: '100%', width: 350 }} maxLength={9} {...phoneMask} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                    <InputLabel>Data de Nasc.</InputLabel>
                        <Field name="birthDate" type="date" component={renderTextField} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>   

                    <Grid item xs={isMobile?12:6}>
                        <Field  disabled={isAdmin?false:true} id="status" name="status" component={renderSelectField} label="Status" style={{ maxWidth: '100%', width: 350 }}>
                            <option value={'A'}>ATIVO</option>
                            <option value={'I'}>INATIVO</option>
                            <option value={'P'}>PENDENTE</option>
                            <option value={'R'}>REMOVIDO</option>
                        </Field>
                    </Grid>                                      

                    <Grid item xs={isMobile?12:6}>
                        <Field name="valpassword" onFocus={clearOnEnterPassword} type="password" component={renderTextField} label="Senha" style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="confpassword" type="password" component={renderTextField} label="Senha Confirmação" style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>       

                    <Grid item xs={isMobile?12:6}>
                        <Field  id="cargo" name="cargo" component={renderSelectField} label="Cargo de Liderança" style={{ maxWidth: '100%', width: 350 }}>
                            <option value={''}>Nenhum</option>
                            <option value={'A'}>ASSESSOR</option>
                            <option value={'V'}>VEREADOR</option>
                            <option value={'E'}>DEP. ESTADUAL</option>
                            <option value={'F'}>DEP. FEDERAL</option>
                            <option value={'S'}>SENADOR</option>
                            <option value={'P'}>PREFEITO</option>
                            <option value={'G'}>GOVERNADOR</option>
                            <option value={'O'}>OUTROS</option>
                            <option value={'PC'}>PRE-CADASTRO</option>
                        </Field>
                    </Grid>  

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={isAdmin?false:true} name="ownerId" component={renderSelectField} label="Indicado Por" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(responsavelList)}
                        </Field>
                    </Grid> 

                    <Grid item xs={isMobile?12:6}>
                        <Field 
                        // disabled={isAdmin?false:true} 
                        name="profileId" component={renderSelectField} label="Perfil" style={{ width: 350 }}>
                            {renderSelection(profileList)}
                        </Field>
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="profissao" type="text" label="Profissão" component={renderTextField} normalize={upper} style={{maxWidth: '100%', width: 350}} >
                        </Field>
                    </Grid>   

                    <Grid item xs={isMobile?12:6}>
                        <Field  id="statusProfissional" name="statusProfissional" component={renderSelectField} label="Situação Profissional" style={{ maxWidth: '100%', width: 350 }}>
                            <option value={'NENHUMA'}>NENHUMA</option>
                            <option value={'PROCURANDO'}>PROCURANDO EMPREGO</option>
                            <option value={'EMPREGADO'}>EMPREGADO</option>                            
                        </Field>
                    </Grid>     

                    <Grid item xs={isMobile?12:6}>
                        <Field name="rg" type="text" component={renderTextField} label="R.G." normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="suscard" type="text" component={renderTextField} label="Cartão do SUS" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={12}>
                        <MyDivider/>  
                        Endereço                    
                    </Grid>                    

                    <Grid item xs={12}>
                        <Field name="cep" type="text" onBlur={getDataByCEP} component={renderTextField} label="CEP" normalize={upper} style={{ maxWidth: '100%', width: 300 }} maxLength={8} {...cepMask} />
                    </Grid>                

                    <Grid item xs={isMobile?12:6}>
                        <Field name="address" type="text" component={renderTextField} label="Endereço" normalize={upper} style={{ maxWidth: '100%', width: 350}} />
                    </Grid>


                    <Grid item xs={isMobile?12:6}>
                        <Field name="number" type="number" component={renderTextField} label="Número" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="neighborhood" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="city" type="text" component={renderTextField} label="Cidade" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <InputLabel>Estado</InputLabel>
                        <Field id="state" name="state" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }}>
                            <option ></option>
                            <option value={'AC'}>Acre</option>
                            <option value={'AL'}>Alagoas</option>
                            <option value={'AP'}>Amapá</option>
                            <option value={'AM'}>Amazonas</option>
                            <option value={'BA'}>Bahia</option>
                            <option value={'CE'}>Ceará</option>
                            <option value={'DF'}>Distrito Federal</option>
                            <option value={'ES'}>Espírito Santo</option>
                            <option value={'GO'}>Goiás</option>
                            <option value={'MA'}>Maranhão</option>
                            <option value={'MT'}>Mato Grosso</option>
                            <option value={'MS'}>Mato Grosso do Sul</option>
                            <option value={'MG'}>Minas Gerais</option>
                            <option value={'PA'}>Pará</option>
                            <option value={'PB'}>Paraíba</option>
                            <option value={'PR'}>Paraná</option>
                            <option value={'PE'}>Pernambuco</option>
                            <option value={'PI'}>Piauí</option>
                            <option value={'RJ'}>Rio de Janeiro</option>
                            <option value={'RN'}>Rio Grande do Norte</option>
                            <option value={'RS'}>Rio Grande do Sul</option>
                            <option value={'RO'}>Rondônia</option>
                            <option value={'RR'}>Roraima</option>
                            <option value={'SC'}>Santa Catarina</option>
                            <option value={'SP'}>São Paulo</option>
                            <option value={'SE'}>Sergipe</option>
                            <option value={'TO'}>Tocantins</option>
                        </Field>
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="complement" type="text" component={renderTextField} label="Complemento" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Photo user={entity} />
                    </Grid>

                    {entity.id &&
                        <Grid item xs={12}>
                            <UploadFile context="users" label="Incluir Documentos/Currículo em PDF" entity={entity} list={entity.files}></UploadFile>
                        </Grid>
                    }        

                    <Grid item xs={12}>                        
                        <MyDivider/>                                                      
                    </Grid>    
                    
                    <Grid item xs={12}>
                        <Field checked={formCompleto} type="text" component={renderSwitch} onChange={clickFormCompleto} label="Mostrar Formulário Completo (Opcional)" style={{ maxWidth: '100%', width: 100}} />
                    </Grid>   

                    { formCompleto &&
                    <>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="type" type="text" label="Profissão" component={renderTextField} normalize={upper} style={{maxWidth: '100%', width: 350}} >
                        </Field>
                    </Grid>                      

                    <Grid item xs={isMobile?12:6}>
                        <Field name="socialname" type="text" component={renderTextField} label="Nome Social" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="wifename" type="text" component={renderTextField} label="Nome Cônjuge" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>
                    
                    <Grid item xs={isMobile?12:6}>
                        <Field name="email" type="email" component={renderTextField} label="Email" normalize={lower} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={isAdmin?false:true} label="Partido" name="secretaria" component={renderSelectField}  style={{maxWidth: '100%', width: 350}} >
                            <option value={'Nenhuma'}>Nenhuma</option>
                            <option value={'MDB'}>MDB</option>
                            <option value={'PT'}>PT</option>
                            <option value={'PSDB'}>PSDB</option>
                            <option value={'PP'}>PP</option>
                            <option value={'PDT'}>PDT</option>
                            <option value={'UNIAO BR'}>UNIAO BR</option>
                            <option value={'PTB'}>PTB</option>
                            <option value={'PL'}>PL</option>
                            <option value={'PSB'}>PSB</option>
                            <option value={'Republicanos'}>Republicanos</option>
                            <option value={'Cidadania'}>Cidadania</option>
                            <option value={'PSC'}>PSC</option>
                            <option value={'PCdoB'}>PCdoB</option>
                            <option value={'PODE'}>PODE</option>
                            <option value={'PSD'}>PSD</option>
                            <option value={'PV'}>PV</option>
                            <option value={'Patriota'}>Patriota</option>
                            <option value={'Solidariedade'}>Solidariedade</option>
                            <option value={'PSOL'}>PSOL</option>
                            <option value={'Avante'}>Avante</option>
                            <option value={'PMN'}>PMN</option>
                            <option value={'Agir'}>Agir</option>
                            <option value={'DC'}>DC</option>
                            <option value={'PRTB'}>PRTB</option>
                            <option value={'PROS'}>PROS</option>
                            <option value={'PMB'}>PMB</option>
                            <option value={'REDE'}>REDE</option>
                            <option value={'NOVO'}>NOVO</option>
                            <option value={'PSTU'}>PSTU</option>
                            <option value={'PCB'}>PCB</option>
                            <option value={'PCO'}>PCO</option>
                            <option value={'UP'}>UP</option>
                        </Field>
                    </Grid>                       

                    <Grid item xs={12}>
                        <div >    
                        <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmiTdTowI7DxNIodiBXI0d6JkCqOuGwA0&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{maxWidth: '100%', width: 800 , height: `100%` }} />}
                            containerElement={<div style={{maxWidth: '100%', width: 800 , height: `100%` }} />}
                            center={position}                            
                            mapElement={<div style={{ height: `300px`}} />}
                        />
                        </div>  
                    </Grid>    

                    <Grid item xs={isMobile?12:6}>
                        <Field name="latitude" type="text" component={renderTextField} label="latitude" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>    

                    <Grid item xs={isMobile?12:6}>
                        <Field name="longitude" type="text" component={renderTextField} label="Longitude" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>                                      


                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleGeoLocation} 
                            style={{ maxWidth: '100%', width:isMobile?350:350 }}                           
                            >
                            Localização Atual
                        </Button>
                    </Grid>                      

                    <Grid item xs={12}>                        
                        <MyDivider/>  
                        Mídias Sociais                    
                    </Grid>    

                    <Grid item xs={isMobile?12:6}>
                        <Field name="site" type="text" component={renderTextField} label="Site" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="whatsapp" type="text" component={renderTextField} label="Whatsapp" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="facebook" type="text" component={renderTextField} label="Facebook" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>      

                    <Grid item xs={isMobile?12:6}>
                        <Field name="instagram" type="text" component={renderTextField} label="Instagram" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field name="twitter" type="text" component={renderTextField} label="Twitter" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>                                        

                    <Grid item xs={isMobile?12:6}>
                        <Field name="telegram" type="text" component={renderTextField} label="Telegram" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>    
                    </>
                    }                                    

                    
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues: {
        'status':'A',
        'birthDate': moment().format('YYYY-MM-DD'),
        'valpassword':  '000000000',
        'confpassword': '000000000',
        'state': 'PB',
        
    }
})(Form);

export default Form;

// export default withStyles(useStyles)(Profile)


