// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#descricao {
  display: flex;
  overflow: hidden;
}

#txt-descricao {
  margin-left: 10vw;

}
#foto-logo img{  
  height: 100%;
}

#titulo-descricao {
  color: #0080cb;
  font-size: 3.5rem;
  margin-bottom: 3vh;
  font-family: 'Poppins', sans-serif;
  position: relative;
  width: auto;  
  /* margin-top: 50px; */
}
#paragrafo-descricao{
  width: 100%;
  font-size: 1rem;
  line-height: 1.2rem;
  inline-size: 60vw;
}

#main-descricao {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  flex-direction: row-reverse;
}

.foto-descricao {
  overflow: hidden;
}

.foto-descricao img{
  width: 100%;
  margin-top:116.4px;
  margin-left: 2px;
  position: relative;
  
}


`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/Components/styles/descricao.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;;AAEnB;AACA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,kCAAkC;EAClC,kBAAkB;EAClB,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,8BAA8B;EAC9B,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;;AAEpB","sourcesContent":["\n#descricao {\n  display: flex;\n  overflow: hidden;\n}\n\n#txt-descricao {\n  margin-left: 10vw;\n\n}\n#foto-logo img{  \n  height: 100%;\n}\n\n#titulo-descricao {\n  color: #0080cb;\n  font-size: 3.5rem;\n  margin-bottom: 3vh;\n  font-family: 'Poppins', sans-serif;\n  position: relative;\n  width: auto;  \n  /* margin-top: 50px; */\n}\n#paragrafo-descricao{\n  width: 100%;\n  font-size: 1rem;\n  line-height: 1.2rem;\n  inline-size: 60vw;\n}\n\n#main-descricao {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n  position: absolute;\n  flex-direction: row-reverse;\n}\n\n.foto-descricao {\n  overflow: hidden;\n}\n\n.foto-descricao img{\n  width: 100%;\n  margin-top:116.4px;\n  margin-left: 2px;\n  position: relative;\n  \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
