import Chart from "chart.js";

export const renderPieChart = (
  chartRef,
  chartData,
  useDistinctColors = false
) => {
  if (chartRef && chartRef.current) {
    let chartInstance = chartRef.current.chart;

    if (!chartInstance) {
      const ctx = chartRef.current.getContext("2d");

      let colors;
      if (useDistinctColors) {
        colors = generateDistinctColors(chartData.dataPoints.length);
      } else {
        colors = generateChartColors(chartData.dataPoints.length);
      }

      chartInstance = new Chart(ctx, {
        type: "pie",
        data: {
          labels: chartData.labels,
          datasets: [
            {
              label: "Distribuição por tipo",
              data: chartData.dataPoints.map((value) => parseFloat(value)),
              backgroundColor: colors,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "left",
              align: "center",
              labels: {
                padding: 20,
              },
            },
          },
        },
      });

      // Salvar a instância do gráfico no elemento canvas para referências futuras
      chartRef.current.chart = chartInstance;
    } else {
      let colors;
      if (useDistinctColors) {
        colors = generateDistinctColors(chartData.dataPoints.length);
      } else {
        colors = generateChartColors(chartData.dataPoints.length);
      }

      chartInstance.data.labels = chartData.labels;
      chartInstance.data.datasets[0].data = chartData.dataPoints.map((value) =>
        parseFloat(value)
      );
      chartInstance.data.datasets[0].backgroundColor = colors;

      chartInstance.update(); // Atualizar o gráfico com os novos dados
    }
  }
};



export const generateChartColors = (numColors) => {
  const defaultColors = [
    "rgba(255, 99, 132, 0.6)",
    "rgba(54, 162, 235, 0.6)",
    "rgba(255, 206, 86, 0.6)",
    "rgba(75, 192, 192, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(255, 159, 64, 0.6)",
    "rgba(255, 99, 132, 0.6)",
  ];

  // Garante que tenhamos cores suficientes para o dataset
  // Caso tenhamos mais de 7 cores, usamos as 7 primeiras novamente
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    colors.push(defaultColors[i % defaultColors.length]);
  }

  return colors;
};

export function generateDistinctColors(numColors) {
  const colors = [];
  const goldenRatioConjugate = 0.618033988749895; // Número para garantir variedade de cores

  for (let i = 0; i < numColors; i++) {
    const hue = (i * goldenRatioConjugate) % 1;
    const saturation = 0.6;
    const lightness = 0.6;

    const color = `hsl(${hue * 360}, ${saturation * 100}%, ${
      lightness * 100
    }%)`;
    colors.push(color);
  }

  return colors;
}

export function prepareDataForPieChart(jsonData, columnNameExtractor) {
  const data = jsonData;

  // Objeto para contar a frequência de cada valor na coluna especificada
  const valueCount = {};

  // Contagem dos valores na coluna especificada
  data.rows.forEach((row) => {
    const value = columnNameExtractor(row);
    if (valueCount[value]) {
      valueCount[value]++;
    } else {
      valueCount[value] = 1;
    }
  });

  // Total de registros
  const totalRegistros = data.rows.length;

  // Preparando os dados para o gráfico de pizza
  const labels = [];
  const dataPoints = [];

  // Calculando a porcentagem e formatando os dados
  for (let value in valueCount) {
    const count = valueCount[value];
    const porcentagem = (count / totalRegistros) * 100;

    labels.push(value);
    dataPoints.push(porcentagem.toFixed(2)); // Ajusta a precisão conforme necessário
  }

  // Retornando os dados formatados
  return {
    labels: labels,
    dataPoints: dataPoints,
  };
}
