import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { api } from "../../services";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Icon from "../../components/icon/icons";

export const ActionsContainer = (props) => {
  const modelUrl = props.url;
  const history = useHistory();
  const deleteCallback = props.deleteCallback;
  const deleteCallbackFailure = props.deleteCallbackFailure;
  const deleteUrl = props.deleteUrl;
  const modelName = props.modelName;
  const hiddenUpdateButton = props.hiddenUpdateButton || false;
  const hiddenDeleteButton = props.hiddenDeleteButton || false;
  const pkid = props.pkid || "";
  const addCustomAction = props.addCustomAction;

  const widthSize = 50;
  const heightSize = 40;

  const row = props.row;
  let deleting = false;

  if (row.dayofweek !== undefined && Number.isInteger(row.dayofweek)) {
    row.dayofweek = getDayFromInteger(row.dayofweek);
  }

  function getDayFromInteger(integerDay) {
    const days = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sabado",
    ];
    return days[integerDay];
  }

  const viewClick = () => {
    let url;

    if (pkid !== "") {
      let lpkid = eval("row." + pkid);
      url = ("/" + modelUrl + "/" + lpkid).replace("//", "/");
    } else {
      url = ("/" + modelUrl + "/" + row.id).replace("//", "/");
    }

    history.push(url);
  };

  const customClick = () => {
    let url;

    if (addCustomAction && addCustomAction.title) {
      url = ("/" + addCustomAction.title + "/" + row.id).replace("//", "/");
      console.log("URL", url);
      history.push(url);
    }

    if (pkid !== "") {
      let lpkid = eval("row." + pkid);
      return lpkid;
    } else {
      return row.id;
    }
  };

  const deleteClick = () => {
    let lpkid = row.id;
    pkid !== "" ? (lpkid = eval("row." + pkid)) : (lpkid = row.id);

    confirmAlert({
      title: "Confirma exclusão",
      message: `Confirma exclusão do ${modelName}: ${
        row.name ? row.name : lpkid
      }?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            deleting = true;
            api
              .delete(`${deleteUrl}/${lpkid}`)
              .then((response) => {
                deleteCallback(response);
                deleting = false;
              })
              .catch((error) => {
                deleteCallbackFailure(error);
                deleting = false;
              });
          },
        },
        {
          label: "Não",
          onClick: () => console.log(`ignoring exclusion ${lpkid}`),
        },
      ],
    });
  };

  return (
    <div>
      {!hiddenUpdateButton && (
        <Button
          type="button"
          color="secondary"
          variant="contained"
          style={{
            marginRight: 3,
            maxWidth: widthSize,
            maxHeight: heightSize,
            minWidth: widthSize,
            minHeight: heightSize,
          }}
          onClick={viewClick}
        >
          <Icon icon="compose" size={18} color="#fff" />
        </Button>
      )}

      {!hiddenDeleteButton && (
        <Button
          type="button"
          color="inherit"
          variant="contained"
          onClick={deleteClick}
          disabled={deleting}
          style={{
            maxWidth: widthSize,
            maxHeight: heightSize,
            minWidth: widthSize,
            minHeight: heightSize,
          }}
        >
          <Icon icon="trash" size={18} color="#000" />
        </Button>
      )}

      {addCustomAction && (
        <Button
          type="button"
          color="inherit"
          variant="contained"
          onClick={(row) => addCustomAction.onClick(customClick())}
          style={{
            maxWidth: widthSize,
            maxHeight: heightSize,
            minWidth: widthSize,
            minHeight: heightSize,
          }}
        >
          <Icon icon={addCustomAction.icon} size={18} color="#000" />
        </Button>
      )}
    </div>
  );
};
