// utils.js
export function removeAccents(str) {
  // Dicionário de caracteres acentuados e suas versões sem acento
  const accentMap = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    â: "a",
    ê: "e",
    î: "i",
    ô: "o",
    û: "u",
    ã: "a",
    õ: "o",
    ç: "c",
    ñ: "n",
    ü: "u",
    ý: "y",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Â: "A",
    Ê: "E",
    Î: "I",
    Ô: "O",
    Û: "U",
    Ã: "A",
    Õ: "O",
    Ç: "C",
    Ñ: "N",
    Ü: "U",
    Ý: "Y",
  };

  // Utiliza uma expressão regular para buscar caracteres acentuados e substituí-los
  return str.replace(/[áàâãéèêíïóôõöúçñ]/gi, function (matched) {
    return accentMap[matched];
  });
}

// Função para processar os dados e retornar os dados formatados para o gráfico de pizza
export function prepareDataForPieChart(jsonData) {
  // Transformando o JSON em um objeto JavaScript
  const data = jsonData;

  // Objeto para contar a frequência de cada tipo
  const tipoCount = {};

  // Contagem dos tipos
  data.rows.forEach((row) => {
    if (tipoCount[row.tipo]) {
      tipoCount[row.tipo]++;
    } else {
      tipoCount[row.tipo] = 1;
    }
  });

  // Total de registros
  const totalRegistros = data.rows.length;

  // Preparando os dados para o gráfico de pizza
  const labels = [];
  const dataPoints = [];

  // Calculando a porcentagem e formatando os dados
  for (let tipo in tipoCount) {
    const count = tipoCount[tipo];
    const porcentagem = (count / totalRegistros) * 100;

    labels.push(tipo);
    dataPoints.push(porcentagem.toFixed(2)); // Ajusta a precisão conforme necessário
  }

  // Retornando os dados formatados
  return {
    labels: labels,
    dataPoints: dataPoints,
  };
}

export function generateChartColors(numColors) {
  const defaultColors = [
    "rgba(255, 99, 132, 0.6)",
    "rgba(54, 162, 235, 0.6)",
    "rgba(255, 206, 86, 0.6)",
    "rgba(75, 192, 192, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(255, 159, 64, 0.6)",
    "rgba(255, 99, 132, 0.6)",
  ];

  // Ensure we have enough colors for the dataset
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    colors.push(defaultColors[i % defaultColors.length]);
  }

  return colors;
}

export const applyPattern = (pattern, value) => {
  // Remove todos os caracteres não numéricos do valor
  const cleanValue = value.replace(/\D/g, '');
  
  // Lógica para aplicar o padrão
  let formattedValue = '';
  let valueIndex = 0;
  
  // Itera sobre o padrão e aplica o valor limpo
  for (let i = 0; i < pattern.length; i++) {
      const patternChar = pattern[i];
      
      if (patternChar === '9') {
          if (valueIndex < cleanValue.length) {
              formattedValue += cleanValue[valueIndex];
              valueIndex++;
          } else {
              break;
          }
      } else {
          formattedValue += patternChar;
      }
  }
  
  return formattedValue;
};