import React from "react";
import { GridList } from "../../components/GridList/GridList";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ToastsStore } from "react-toasts";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const ListVisitas = (props) => {
  const classes = useStyles();

  const deleteCallback = (response) => {
    ToastsStore.success("Visita excluída com sucesso.");
  };

  const deleteCallbackFailure = (response) => {
    // Handle failure case here
  };

  const columns = [
    { name: "vote", title: "Voto", width: 130, filterable: true },
    {
      name: "$visitado.phone$",
      title: "Contato",
      width: 130,
      filterable: true,
      getCellValue: (row) => (row.visitado ? row.visitado.phone : null),
    },
    {
      name: "$visitado.name$",
      title: "Entrevistado",
      width: 130,
      filterable: true,
      getCellValue: (row) => (row.visitado ? row.visitado.name : null),
    },
    {
      name: "$responsavel.name$",
      title: "Responsável",
      width: 130,
      filterable: true,
      getCellValue: (row) => (row.responsavel ? row.responsavel.name : null),
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <Card className="cardPersonalData">
            <CardHeader title="Visitas" />
            <Divider />
            <CardContent>
              <Grid container spacing={3} wrap="wrap">
                <GridList
                  modelName={"Visita"}
                  editModelRoute={"/registroVisita"}
                  newModelRoute={"/registroVisita"}
                  deleteCallback={deleteCallback}
                  deleteCallbackFailure={deleteCallbackFailure}
                  endpoint={"pesquisa"}
                  enableActions={true}
                  columns={columns}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ListVisitas;
