import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import { validate } from "../../services";
import { Grid } from "@material-ui/core";
import {
  renderTextField,
  renderSelectField,
  renderTextAreaField,
  renderDatePicker,
} from "../../components/Fields/Fields";
import BasicForm from "../../components/BasicForm";
import api from "../../services/api";
import { isMobile } from "react-device-detect";
import { moneyMask } from "../../services/masks";
import { useParams } from "react-router-dom";
import {
  phoneMask,
  DateMaskDDMMYYYY,
  cepMask,
  cpfMask2,
} from "../../services/masks";
import apiLatLng from "../../services/apiLatLng";
import { ToastMessage } from "../../layouts";

const formName = "bairro/eventos";
const modelName = "bairro/eventos";
const label = "Eventos Bairro";

let Form = (props) => {
  const [loadingResponsavel, setLoadingResponsavel] = useState(false);
  const [responsavelList, setResponsavelList] = useState([]);
  const [bairroList, setBairroList] = useState([]);
  const [loadingBairro, setLoadingBairro] = useState(false);

  const { userId } = useParams();

  useEffect(() => {
    setUserOwner();
    getResponsaveisList();
    getBairroList();
  }, []);

  const getResponsaveisList = () => {
    if (!loadingResponsavel) {
      setLoadingResponsavel(true);
      api.get("/users/responsavel?pageSize=-1").then((list) => {
        let result = [];
        list.data.rows.forEach((item) => {
          result.push(item.user);
        });
        setResponsavelList(result);
      });
    }
  };

  const getBairroList = () => {
    if (!loadingBairro) {
      setLoadingBairro(true);
      api.get("/bairro?pageSize=-1").then((list) => {
        console.log(list.data.rows);
        setBairroList(list.data.rows);
      });
    }
  };

  const renderSelection = (list) => {
    if (list && list.length > 0) {
      let finalList = [<option key={"none"}></option>];
      list.forEach((entity) => {
        finalList.push(
          <option key={entity.id} value={entity.id}>
            {entity.titulo || entity.nome || entity.name}
          </option>
        );
      });
      return finalList;
    }
  };
  const renderSelection2 = (list) => {
    if (list && list.length > 0) {
      let finalList = [<option key={"none"} value={""}></option>];
      list.forEach((entity) => {
        finalList.push(
          <option key={entity.id} value={entity.id}>
            {entity.name}
          </option>
        );
      });
      return finalList;
    }
  };
  const setUserOwner = () => {
    if (userId && loadingResponsavel) {
      props.dispatch(change(formName, "ownerId", userId));
    }
  };

  const getDataByCEP = (event) => {
    const numberPattern = /\d+/g;
    let cep = (event.target.value.match( numberPattern ) || []).join("");

    if (cep) {
        apiLatLng.get(`${cep}`)
        .then(data => {
            const values = data.data;
            console.log('DATA', values)
            props.dispatch(change(formName, 'latitude', values.lat));
            props.dispatch(change(formName, 'longitude', values.lng));
        })
        .catch(error => {
            console.error(error);
            ToastMessage.error('CEP não encontrado');
        })
    }
}

  return (
    <BasicForm
      keepUpdateAfterSubmit={true}
      routeAfterSubmit="/adicionaeventobairro"
      imageContext={"registroAtividadesCfg"}
      noLoadData={userId ? true : false}
      maxImages={5}
      content={
        <Grid container spacing={3} wrap="wrap">
          <Grid item xs={12}>
            <Field
              name="solicitante_id"
              component={renderSelectField}
              label="Solicitante"
              style={{ maxWidth: "100%", width: 350 }}
            >
              {renderSelection(responsavelList)}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="empresa"
              component={renderTextField}
              label="Empresa/Orgão"
              style={{ maxWidth: "100%", width: 350 }}
            ></Field>
          </Grid>

          <Grid item xs={12}>
            <Field
              name="descricao"
              type="text"
              component={renderTextField}
              label="Título"
              normalize={(value) => value && value.toUpperCase()}
              style={{ maxWidth: "100%", width: 700 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="detalhe"
              type="text"
              component={renderTextAreaField}
              label="Descrição"
              normalize={(value) => value && value.toUpperCase()}
              style={{ maxWidth: "100%", width: 700 }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              label="Tipo"
              name="tipo"
              component={renderSelectField}
              style={{ maxWidth: "100%", width: 350 }}
            >
              <option value={"Saude"}>Saúde</option>
              <option value={"Infraestrutura"}>Infraestrutura</option>
              <option value={"Evento"}>Evento</option>
              <option value={"Governanca"}>Governança</option>
            </Field>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              label="Status"
              name="status"
              component={renderSelectField}
              style={{ maxWidth: "100%", width: 350 }}
            >
              <option value={"Ativo"}>Ativo</option>
              <option value={"Inativo"}>Inativo</option>
              <option value={"Removido"}>Removido</option>
              <option value={"Terminado"}>Terminado</option>
            </Field>
          </Grid>

          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              name="valor"
              type="text"
              component={renderTextField}
              label="Valor"
              style={{ maxWidth: "100%", width: 350 }}
              {...moneyMask}
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              name="data"
              type="date"
              component={renderDatePicker}
              label="Data"
              normalize={(value) => value && value.toUpperCase()}
              style={{ maxWidth: "100%", width: 350 }}
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              name="bairro_id"
              component={renderSelectField}
              label="Bairro Beneficiário"
              style={{ maxWidth: "100%", width: 350 }}
            >
              {renderSelection2(bairroList)}
            </Field>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              name="rua"
              type="text"
              component={renderTextField}
              label="Rua"
              style={{ maxWidth: "100%", width: 350 }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              name="cep"
              type="text"
              component={renderTextField}
              label="CEP"
              style={{ maxWidth: "100%", width: 350 }}
              normalize={(value) => value && value.replace(/[^0-9]/g, "")}
              {...cepMask}
              {...{onBlur: getDataByCEP }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              name="latitude"
              type="text"
              disabled
              component={renderTextField}
              label="Latitude"
              style={{ maxWidth: "100%", width: 350 }}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Field
              name="longitude"
              type="text"
              disabled
              component={renderTextField}
              label="Longitude"
              style={{ maxWidth: "100%", width: 350 }}
            />
          </Grid>
        </Grid>
      }
      formName={formName}
      modelName={modelName}
      label={label}
      {...props}
    />
  );
};

Form = reduxForm({
  form: formName,
  validate,
  initialValues: {
    status: "Ativo",
    tipo: "Saude",
  },
})(Form);

export default Form;
