import React, { useEffect, useState } from 'react';
import { GridList } from '../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import { isMobile } from 'react-device-detect';
import CardList from '../../components/CardList/CardList';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    }
}));

const columns = [
    { name: '$solicitante.name$', title: 'Nome', getCellValue: row => row.solicitante ? row.solicitante.name : null, filterable: true, primary: true },
    { name: 'descricao', title: 'Descrição', width: 150, filterable: true, secondary: true },
    { name: 'valor', title: 'Valor', getCellValue: row => row.valor ? 'R$ ' + row.valor : null, width: 100, filterable: false, content: true },
    { name: '$bairro.name$', title: 'Bairro', getCellValue: row => row.bairro ? row.bairro.name : null, width: 150, filterable: false }
];

export default function RegistroAtividadeCfgList(props) {
    const classes = useStyles();
    const getid = () => {
        const authData = JSON.parse(sessionStorage.getItem('auth'));
        if (authData && authData.userInfo && authData.userInfo.id) {
            const id = authData.userInfo.id;
            return id;
        }
        return ''
    }
    const [id, setid] = useState(getid);
    useEffect(() => {
        const authData = JSON.parse(sessionStorage.getItem('auth'));
        if (authData && authData.userInfo && authData.userInfo.id && authData.profile.name !== "ADMIN" && authData.profile.name !== "DONO") {
            const id = authData.userInfo.id;
            setid(id); 
        } else {
            setid('')
        }
    }, [getid]);

    const deleteCallback = (response) => {
        ToastsStore.success('Secretaria excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {
        // Handle delete failure
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    <Card className='cardPersonalData'>
                        <CardHeader title="Eventos Por Bairro" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3} wrap="wrap">
                                    <GridList
                                        modelName={"Evento Por Bairro"}
                                        editModelRoute={'/adicionaeventobairro'}
                                        newModelRoute={'/adicionaeventobairro/new'}
                                        deleteCallback={deleteCallback}
                                        deleteCallbackFailure={deleteCallbackFailure}
                                        endpoint={`bairro/eventos`}
                                        enableActions={true}
                                        columns={columns}
                                    />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
