// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-drop-box {
    background-color: rgb(230, 230, 230);
    border: 2px dotted rgb(200, 200, 200);
    border-radius: 5px;
    height: 120px;
    width: 120px;
}

.centered-image {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
    width: 116px;
    height: 116px;
}

.img-div {
    height: 120px;
}

.float-button {
    position: fixed;
    bottom: 15px;
    left: 45px;
    width: 5px;
    height: 20px;
}

.add-button {
    position: relative;
    width: 100%;
    height: 100%;
}

.hiddenInputFile {
    visibility: hidden
}`, "",{"version":3,"sources":["webpack://./src/components/ImageDropAndCrop/image-drop-box.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,qCAAqC;IACrC,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,UAAU;IACV,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;AACJ","sourcesContent":[".img-drop-box {\n    background-color: rgb(230, 230, 230);\n    border: 2px dotted rgb(200, 200, 200);\n    border-radius: 5px;\n    height: 120px;\n    width: 120px;\n}\n\n.centered-image {\n    position: relative;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 100%;\n    max-height: 100%;\n    width: 116px;\n    height: 116px;\n}\n\n.img-div {\n    height: 120px;\n}\n\n.float-button {\n    position: fixed;\n    bottom: 15px;\n    left: 45px;\n    width: 5px;\n    height: 20px;\n}\n\n.add-button {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.hiddenInputFile {\n    visibility: hidden\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
