// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/*Home*/
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-home img {
  width: 100%;
}

.logo-home{
  position: relative;
  height: auto;
}
  
.logo-transicao{
  position: relative;
  margin-top: -20px;
}




  /* ----------------------- */

  @media screen and (max-width: 750px)  {

    .logo-transicao{
      position: absolute;
      margin-top: -15px;
        
    }
  }

  @media screen and (max-width: 500px)  {
      
    .logo-transicao{
      position: absolute;
      margin-top: -10px;
    }
  }
  
  @media screen and (max-width: 400px)  {
      
    .logo-transicao{
      position: absolute;
      margin-top: -10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/Components/styles/home.css"],"names":[],"mappings":";;AAEA,OAAO;AACP;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;;;;EAKE,4BAA4B;;EAE5B;;IAEE;MACE,kBAAkB;MAClB,iBAAiB;;IAEnB;EACF;;EAEA;;IAEE;MACE,kBAAkB;MAClB,iBAAiB;IACnB;EACF;;EAEA;;IAEE;MACE,kBAAkB;MAClB,iBAAiB;IACnB;EACF","sourcesContent":["\n\n/*Home*/\n.home {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.logo-home img {\n  width: 100%;\n}\n\n.logo-home{\n  position: relative;\n  height: auto;\n}\n  \n.logo-transicao{\n  position: relative;\n  margin-top: -20px;\n}\n\n\n\n\n  /* ----------------------- */\n\n  @media screen and (max-width: 750px)  {\n\n    .logo-transicao{\n      position: absolute;\n      margin-top: -15px;\n        \n    }\n  }\n\n  @media screen and (max-width: 500px)  {\n      \n    .logo-transicao{\n      position: absolute;\n      margin-top: -10px;\n    }\n  }\n  \n  @media screen and (max-width: 400px)  {\n      \n    .logo-transicao{\n      position: absolute;\n      margin-top: -10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
