import React, { useRef, useState } from "react";
import { GridList } from "../../../components/GridList/GridList";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  Modal,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ToastsStore } from "react-toasts";
import { Loading } from "../../../components/Loading/Loading";
import { api } from "../../../services";
import { ToastMessage } from "../../../layouts";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
    maxHeight: "80%",
    overflowY: "auto",
  },
  resumeContainer: {
    width: "1440px", // Largura aproximada de uma folha A4 em modo paisagem
    minHeight: "2800px", // Altura aproximada de uma folha A4 em modo paisagem
    backgroundColor: "#f5f5f5",
    margin: "auto",
    marginTop: theme.spacing(4),
    
    padding: theme.spacing(4),
    
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionHeader: {
    fontWeight: "bold",
    fontSize: "1.2em",
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  field: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    borderBottom: "1px solid #ccc",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const ResumeTemplate = ({ data }) => {
  const classes = useStyles();
  if (!data) return null;

  return (
    <div className={classes.resumeContainer}>
      <div className={`${classes.section} ${classes.divider}`}>
        <h1>{data.name}</h1>
        <p className={classes.field}>
          <strong>Email:</strong> {data.email}
        </p>
        <p className={classes.field}>
          <strong>Telefone:</strong> {data.phone}
        </p>
        <p className={classes.field}>
          <strong>Endereço:</strong> {data.address}, {data.number}{" "}
          {data.complement}
        </p>
        <p className={classes.field}>
          <strong>CEP:</strong> {data.cep}
        </p>
        <p className={classes.field}>
          <strong>Bairro:</strong> {data.neighborhood}
        </p>
        <p className={classes.field}>
          <strong>Cidade:</strong> {data.city} - {data.state}
        </p>
      </div>
      <div className={`${classes.section} ${classes.divider}`}>
        <h2 className={classes.sectionHeader}>Resumo Profissional</h2>
        <p>{data.professionalSummary}</p>
      </div>
      <div className={`${classes.section} ${classes.divider}`}>
        <h2 className={classes.sectionHeader}>Hobbies</h2>
        <p>{data.hobbies}</p>
      </div>
      {data.jobs.length > 0 && (
        <div className={`${classes.section} ${classes.divider}`}>
          <h2 className={classes.sectionHeader}>Experiência Profissional</h2>
          {data.jobs.map((job) => (
            <div key={job.id}>
              <h3>
                {job.title} em {job.employer}
              </h3>
              <p>{job.description}</p>
              <p className={classes.field}>
                <strong>Cidade:</strong> {job.city}
              </p>
              <p className={classes.field}>
                <strong>Data de Início:</strong> {job.startDate}
              </p>
              <p className={classes.field}>
                <strong>Data de Término:</strong> {job.endDate}
              </p>
            </div>
          ))}
        </div>
      )}
      {data.educations.length > 0 && (
        <div className={`${classes.section} ${classes.divider}`}>
          <h2 className={classes.sectionHeader}>Educação</h2>
          {data.educations.map((edu) => (
            <div key={edu.id}>
              <h3>
                {edu.degree} em {edu.school}
              </h3>
              <p>{edu.description}</p>
              <p className={classes.field}>
                <strong>Cidade:</strong> {edu.city}
              </p>
              <p className={classes.field}>
                <strong>Estado:</strong> {edu.state}
              </p>
              <p className={classes.field}>
                <strong>País:</strong> {edu.country}
              </p>
              <p className={classes.field}>
                <strong>Data de Início:</strong> {edu.startDate}
              </p>
              <p className={classes.field}>
                <strong>Data de Término:</strong> {edu.endDate}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const ResumeList = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const printRef = useRef();

  const deleteCallback = (response) => {
    ToastsStore.success("Demanda excluída com sucesso.");
  };

  const deleteCallbackFailure = (response) => {};

  const pdfGenerate = (rowId) => {
    setLoading(true);
    api
      .get(`resumes/${rowId}`)
      .then((response) => {
        console.log("RESPOSTA", response.data);
        setData(response.data);
        setModalOpen(true);
      })
      .catch(() => {
        ToastMessage.warning("Problemas ao gerar currículo em PDF");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generatePDF = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("document.pdf");
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item>
              <Card className="cardPersonalData">
                <CardHeader title="Currículos" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} wrap="wrap">
                    <GridList
                      modelName={"Currículo"}
                      editModelRoute={"/resumes"}
                      newModelRoute={"/resumes/new"}
                      deleteCallback={deleteCallback}
                      deleteCallbackFailure={deleteCallbackFailure}
                      endpoint={"resumes"}
                      enableActions={true}
                      addCustomAction={{
                        icon: "file-pdf",
                        onClick: pdfGenerate,
                      }}
                      columns={[
                        { name: "name", title: "Nome" },
                        { name: "email", title: "Email" },
                        { name: "phone", title: "Telefone" },
                      ]}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <h2>Prévia do PDF</h2>
          {data && (
            <div ref={printRef}>
              <ResumeTemplate data={data} />
            </div>
          )}
          <Button
            onClick={generatePDF}
            color="primary"
            variant="contained"
            style={{ margin: "10px" }}
          >
            Baixar PDF
          </Button>
          <Button
            onClick={() => setModalOpen(false)}
            color="secondary"
            variant="contained"
            style={{ margin: "10px" }}
          >
            Fechar
          </Button>
        </div>
      </Modal>
    </>
  );
};
