// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #1515ab;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/index.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;EACA,mCAAA;EACA,kCAAA;AACF;;AAEA;EACE,yBAAA;EAEA,YAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,YAAA;AAAF","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nhtml {\n  height: 100%;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nbody {\n  background-color: #1515abff;\n  //background-color: #f4f6f8;\n  height: 100%;\n}\n\na {\n  text-decoration: none;\n}\n\n#root {\n  height: 100%;\n}\n\nmain {\n  // width: fit-content !important;\n  // height: fit-content !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
