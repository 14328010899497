import React from 'react';
import './styles/rodape.css'

import conectamersinho from './assets/Logo-conecta-mersinho 1.webp'
// import youtube from './assets/Youtube.png'
// import facebook from './assets/Facebook.png'
// import instagram from './assets/Instagram.png'
// import twitter from './assets/Twitter.png'
import { BiSolidPhoneCall } from 'react-icons/bi';
import {BiSolidEnvelope} from 'react-icons/bi';
function Rodape () {

    return( <footer id="rodape">

        <div id="logo-rodape">
            <div id="descricao-rodape">
                <p>
                Construa Pontes, Faça a Diferença! Nosso site é o seu ponto de partida para se envolver ativamente na política. Conecte-se com líderes, participe de debates e influencie mudanças. Juntos, podemos moldar um futuro melhor. Junte-se a nós agora e seja parte da transformação!
                </p>
            </div>
            <div id="conectamersinho">
                <img src={conectamersinho} alt="conectamersinho" />
            </div>

            
        </div>

        <div id="contato-rodape">

            <div id="entre-contato">
                <h3>Entre em contato</h3>
            </div>

            <div id="contatos">

                <div id="contato-email">
                    <div id="message">
                        <h1><BiSolidEnvelope/></h1>
                    </div>
                    <div id="email">
                        <p>modelo@gmail.com</p>
                    </div>
                </div>

                <div id="contato-numero">

                    <div id="calling">
                        <h1><BiSolidPhoneCall /></h1>
                    </div>

                    <div id="numero">
                        <p>+88 98765 43210</p>
                    </div>

                </div>
                
            </div>
        </div>

        

    </footer>
    );
};

export default Rodape;