import React, { useState, useEffect } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField, renderSelectField, renderCheckbox, renderTextAreaField, renderDatePicker } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import api from '../../../services/api';
import { isMobile } from 'react-device-detect';
import { moneyMask, numberMask } from '../../../services/masks';
import { useParams } from "react-router-dom";

const formName = 'RegistroAtividadesCfg';
const modelName = 'registroAtividadesCfg';
const label = 'Eventos';

let Form = (props) => {
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    const [secretariaList, setSecretariaList] = useState([]);
    const [loadingResponsavel, setLoadingResponsavel] = useState(false);
    const [responsavelList, setResponsavelList] = useState([]);
    const [loadingProcMedicos, setLoadingProcMedicos] = useState(false);
    const [procMedicosList, setProcMedicosList] = useState([]);
    const [tipo, setTipo] = useState('');
    const [recorrencia, setRecorrencia] = useState('');
    const [meses, setMeses] = useState(1); // Estado para armazenar a quantidade de meses
    const [valorTotal, setValorTotal] = useState(0); // Estado para armazenar o valor total

    const { userId } = useParams();

    useEffect(() => {
        setUserOwner();
        getSecretariasList();
        getResponsaveisList();
        getProcMedicosList();
    }, []);

    useEffect(() => {
        calcularValorTotal();
    }, [meses, props.valor]);

    const getSecretariasList = () => {
        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/users/contato?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);
            });
        }
    }

    const getProcMedicosList = () => {
        if (!loadingProcMedicos) {
            setLoadingProcMedicos(true);
            api.get('/procMedicos?pageSize=-1').then(list => {
                setProcMedicosList(list.data.rows);
            });
        }
    }

    const getResponsaveisList = () => {
        if (!loadingResponsavel) {
            setLoadingResponsavel(true);
            api.get('/users/responsavel?pageSize=-1').then(list => {
                let result = [];
                list.data.rows.forEach((item) => {
                    result.push(item.user)
                });
                setResponsavelList(result);
            });
        }
    }

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo || entity.nome || entity.name}</option>);
            })
            return (finalList);
        }
    }

    const setUserOwner = () => {
        if ((userId) && (loadingResponsavel)) {
            props.dispatch(change(formName, 'ownerId', userId));
        }
    }

    const handleTipoChange = (event) => {
        setTipo(event.target.value);
    }

    const handleRecorrenciaChange = (event) => {
        setRecorrencia(event.target.value);
    }

    const handleMesesChange = (event) => {
        setMeses(Number(event.target.value));
    }

    const calcularValorTotal = () => {
        const valor = props.valor ? parseFloat(props.valor.replace(/[^0-9.-]+/g, "")) : 0;
        setValorTotal(valor * meses);
    }

    return (
        <BasicForm
            keepUpdateAfterSubmit={true}
            imageContext={'registroAtividadesCfg'}
            routeAfterSubmit="/home"
            maxImages={5}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12}>
                        <Field name="ownerId" component={renderSelectField} label="Responsável" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(responsavelList)}
                        </Field>
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="descricao" type="text" component={renderTextField} label="Descrição" normalize={value => value && value.toUpperCase()} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="detalhe" type="text" component={renderTextAreaField} label="Detalhamento" normalize={value => value && value.toUpperCase()} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                        <Field label="Tipo" name="tipo" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }} onChange={handleTipoChange} >
                            <option value={'F'}>Financeiro</option>
                            <option value={'M'}>Médico</option>
                            <option value={'I'}>Indicação</option>
                            <option value={'O'}>Outros</option>
                        </Field>
                    </Grid>

                    {tipo === 'M' && (
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field label="Procedimento" name="procedimento" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }}  >
                                <option value={'Exame'}>Exame</option>
                                <option value={'Consulta'}>Consulta</option>
                                <option value={'Cirurgia'}>Cirurgia</option>
                                <option value={'Retorno'}>Retorno</option>
                            </Field>
                        </Grid>
                    )}

                    {tipo === 'I' && (
                        <>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Field label="Recorrência" name="recorrencia" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }} onChange={handleRecorrenciaChange} >
                                    <option value={'Unitario'}>Unitário</option>
                                    <option value={'Recorrente'}>Recorrente</option>
                                </Field>
                            </Grid>

                            {recorrencia === 'Recorrente' && (
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <Field label="Quantidade de Meses" name="quantidadeMeses" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }} onChange={handleMesesChange} >
                                        {[...Array(12).keys()].map(i => (
                                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                                        ))}
                                    </Field>
                                </Grid>
                            )}
                        </>
                    )}

                    <Grid item xs={isMobile ? 12 : 6}>
                        <Field label="status" name="status" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }}  >
                            <option value={'A'}>Ativo</option>
                            <option value={'I'}>Inativo</option>
                            <option value={'R'}>Removido</option>
                        </Field>
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                        <Field name="valor" type="text" component={renderTextField} label="Valor" style={{ maxWidth: '100%', width: 350 }} {...moneyMask} />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                        <Field name="data" type="date" component={renderDatePicker} label="Data" normalize={value => value && value.toUpperCase()} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>
                    
                    {tipo === 'M' && (
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Field name="procedimentosMedicosId" component={renderSelectField} label="Proc. Médico" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(procMedicosList)}
                        </Field>
                    </Grid>
                    )}

                    <Grid item xs={isMobile ? 12 : 6}>
                        <Field name="beneficiario" component={renderSelectField} label="Beneficiário" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                    </Grid>

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            {...props}
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues: {
        'status': 'A',
        'tipo': 'P',
    }
})(Form);

export default Form;
