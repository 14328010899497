import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ToastsStore } from "react-toasts";
import RouteButton from "../../components/RouteButton";
import clsx from "clsx";
import AuthState from "../../auth/Auth";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    margin: theme.spacing(4),
  },
}));

const formName = 'bairro/eventos';

const Relatorio = (props) => {
  const { className, ...rest } = props;
  const profile = props.profile;

  const history = useHistory();
  const classes = useStyles();

  const [location, setLocation] = useState(null);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log('LAT/LNG', latitude, longitude)
          setLocation({latitude,longitude});
          // props.dispatch(change(formName, 'latitude', latitude));
          // props.dispatch(change(formName, 'longitude', longitude));
        },
        (error) => {
          console.error('Error getting location:', error);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };


  // Definição das funções handle...
  const handleClick = (e) => {
    AuthState.getUserInfo().then((luser) => {
      history.push({ pathname: `/relatorioTipo`, state: { tipoRelatorio: e } });
    });
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <Typography>Antes de escolher o relatório, forneca sua localização clicando no botão abaixo</Typography>
        <Button className={classes.button} type="button" color="primary" variant="contained" onClick={handleGetLocation}>
          Pegar Localização
        </Button>
        {location && <Typography>{`${location.latitude} ${location.longitude}`} </Typography>}

      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <CardHeader title={"Relatórios"} />
          <Grid container spacing={3} wrap="wrap">
            <RouteButton
              title={"Saúde"}
              iconName={"aid-kit"}
              iconSize={36}
              fill={"#fff"}
              bgColor={"#3332e4"}
              handlerAction={() => handleClick("Saúde")}
            />

            <RouteButton
              title={"Infraestrutura"}
              iconName={"condominioadmin"}
              iconSize={36}
              fill={"#fff"}
              bgColor={"#3332e4"}
              handlerAction={() => handleClick("Infraestrutura")}
            />

            <RouteButton
              title={"Governança"}
              iconName={"portaria"}
              iconSize={36}
              fill={"#fff"}
              bgColor={"#3332e4"}
              handlerAction={() => handleClick("Governança")}
            />

            <RouteButton
              title={"Evento"}
              iconName={"room"}
              iconSize={36}
              fill={"#fff"}
              bgColor={"#3332e4"}
              handlerAction={() => handleClick("Eventos")}
            />
            <RouteButton
              title={"Todos"}
              iconName={"briefcase"}
              iconSize={36}
              fill={"#fff"}
              bgColor={"#3332e4"}
              handlerAction={() => handleClick("Todos os tipos")}
            />
          </Grid>

          <Divider style={{ marginTop: 50 }} />
        </form>
        <Button
          style={{
            maxWidth: "100%",
            height: 40,
          }}
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>
      </Card>
    </div>
  );
};

export default Relatorio;
