// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crop {
    float: left
}

.cancel {
    float: right
}

.hidden-canvas {
    display: none
}

.ReactCrop__image {
    max-width: 400px;
    max-height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageDropAndCrop/crop-modal.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".crop {\n    float: left\n}\n\n.cancel {\n    float: right\n}\n\n.hidden-canvas {\n    display: none\n}\n\n.ReactCrop__image {\n    max-width: 400px;\n    max-height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
