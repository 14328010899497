import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import AuthState from '../../../../auth/Auth';
import { useHistory } from 'react-router';
import LOCAL_URL from '../../../../services/qrCodeConfig';
import api from '../../../../services/api';
import RouteButton from '../../../../components/RouteButton';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Cracha = (props) => {
  const { className, ...rest } = props;
  const profile = props.profile;
  const classes = useStyles();
  const history = useHistory();

  const [bairroList, setBairroList] = React.useState([]);

  React.useEffect(() => {
    getBairroList();
  }, []);

  const getBairroList = async () => {
    try {
      // Tente obter os dados do cache primeiro
      const cache = await caches.open('bairro-cache');
      const cachedResponse = await cache.match('bairro-list');

      if (cachedResponse) {
        const data = await cachedResponse.json();
        setBairroList(data);
        console.log('Fetched bairro list from cache:', data);
      } else {
        // Se não houver dados no cache, faça a chamada à API
        const response = await api.get('/bairro?pageSize=-1');
        const data = response.data.rows;
        setBairroList(data);
        console.log('Fetched bairro list from API:', data);
        
        // Salve os dados no cache
        const responseToCache = new Response(JSON.stringify(data));
        await cache.put('bairro-list', responseToCache);
      }
    } catch (error) {
      console.error('Failed to fetch or cache bairro list:', error);
    }
  };

  // Definição das funções handle...
  // const handleDashboad = (e) => {
  //   AuthState.getUserInfo().then((luser) => {
  //     history.push(`/dashboad`);
  //   });
  // };

  const handleClick = (e) => {
    AuthState.getUserInfo().then((luser) => {
      history.push(`/users/new`);
    });
  };

  const handleClickListUsers = (e) => {
    AuthState.getUserInfo().then((luser) => {
      history.push(`/users`);
    });
  };

  const handleRegistrar = (e) => {
    history.push(`/escalasAtividadesIndividual`);
  };

  const handleGruposPoliticos = (e) => {
    history.push(`/escalasAtividades`);
  };

  const handleHistorico = (e) => {
    history.push(`/geolocalizacao`);
  };

  const handleEvento = (e) => {
    history.push(`/registroAtividadesCfg`);
  };

  const handleVisit = (e) => {
    history.push(`/registroVisita`);
  };

  const handleMap = (e) => {
    history.push(`/mapaVisitas`);
  };

  const handleSendClipBoard = (e) => {
    history.push(`/registroVisitaList`);
  };

  const handleEventoBairro = (e) => {
    history.push(`/adicionaeventobairro`);
  };

  const handleEventoRelatorio = (e) => {
    history.push(`/relatorios`);
  };

  const handleShare = (e) => {
    try {
      AuthState.getUserInfo().then(async (luser) => {
        const shareData = {
          title: 'Fator Político',
          text: 'Faça seu cadastro no Fator Político',
          url: `${LOCAL_URL}/${luser.id}/regusuario/`,
        };
        await navigator.share(shareData);
      });
    } catch (err) {
      alert('Não suportado');
    }
  };

  const handlePush = (e) => {
    history.push(`/sendpush`);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader title={"Menu do " + profile} />
        <Grid container spacing={3} wrap="wrap">
          {/* <RouteButton
            title={'Dashboard'}
            iconName={'dash'}
            iconSize={36}
            fill={'#fff'}
            bgColor={'#3332e4'}
            handlerAction={handleDashboad}
          /> */}

          <RouteButton
            title={'(+) Contato'}
            iconName={'person_add_alt_1'}
            iconSize={36}
            fill={'#fff'}
            bgColor={'#3332e4'}
            handlerAction={handleClick}
          />

          <RouteButton
            title={'Contatos'}
            iconName={'person'}
            iconSize={36}
            fill={'#fff'}
            bgColor={'#3332e4'}
            handlerAction={handleClickListUsers}
          />

          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Grupos'}
              iconName={'Grupos'}
              iconSize={36}
              fill={'#fff'}
              bgColor={'#3332e4'}
              handlerAction={handleGruposPoliticos}
            />
          )}

          <RouteButton
            title={'Meus Grupos'}
            iconName={'Grupos'}
            iconSize={36}
            fill={'#fff'}
            bgColor={'#3332e4'}
            handlerAction={handleRegistrar}
          />

          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Eventos'}
              iconName={'payment'}
              iconSize={36}
              bgColor={'#3332e4'}
              fill={'#fff'}
              handlerAction={handleEvento}
            />
          )}

          {/* {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Geo'}
              iconName={'map'}
              iconSize={36}
              bgColor={'#3332e4'}
              fill={'#fff'}
              handlerAction={handleHistorico}
            />
          )} */}



          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Compartilhar'}
              iconName={'share'}
              iconSize={36}
              fill={'#fff'}
              bgColor={'#3332e4'}
              handlerAction={handleShare}
            />
          )}

          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Whatsapp'}
              iconName={'podcast'}
              iconSize={36}
              fill={'#fff'}
              bgColor={'#3332e4'}
              handlerAction={handlePush}
            />
          )}

          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Listar Visita'}
              iconName={'users1'}
              iconSize={36}
              fill={'#fff'}
              bgColor={'#3332e4'}
              handlerAction={handleSendClipBoard}
            />
          )}

          <RouteButton
            title={'Adiciona Visita'}
            iconName={'condominio'}
            iconSize={36}
            fill={'#fff'}
            bgColor={'#3332e4'}
            handlerAction={handleVisit}
          />

          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Mapa Visitas'}
              iconName={'location'}
              iconSize={36}
              fill={'#fff'}
              bgColor={'#3332e4'}
              handlerAction={handleMap}
            />
          )}

          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'‎ Eventos Bairros'}
              iconName={'mail'}
              iconSize={36}
              fill={'#fff'}
              bgColor={'#3332e4'}
              handlerAction={handleEventoBairro}
            />
          )}

          {profile && profile !== 'MULTIPLICADOR' && (
            <RouteButton
              title={'Relatorio'}
              iconName={'mail'}
              iconSize={36}
              fill={'#fff'}
              bgColor={'#3332e4'}
              handlerAction={handleEventoRelatorio}
            />
          )}
        </Grid>

        <Divider style={{ marginTop: 50 }} />
      </form>
    </Card>
  );
};

Cracha.propTypes = {
  className: PropTypes.string,
};

export default Cracha;
