import React from 'react';
import ImageDropAndCrop from '../ImageDropAndCrop/ImageDropAndCrop';
import ImageDropAndCropLoading from '../ImageDropAndCrop/ImageDropAndCropLoading';
import ImageCropView from '../ImageDropAndCrop/ImageCropView';
import './carousel.css';
import { useDispatch } from 'react-redux';
import CarouselActions from '../../store/ducks/Carousel';


const Carousel2 = (props) => {

    const dispatch = useDispatch();

    const deleteCallback = props.deleteCallback;
    const images = props.images;
    const addCallback = props.addCallback;
    const entity = props.entity;
    const maxImages = props.maxImages;
    const loading = props.loading;
    const imageContext = props.imageContext;
    const hiddenRemoveImgButton = props.hiddenRemoveImgButton;
    const image = props.entity[0].image;

    return (
        <div id="addctn" className="container">
            <br />
            <hr />
            <br />
            {loading ? (
                <>
                    <div id="addimglb" className="row">Carregando Imagens...</div>
                    <div id="addimgbx" className="box">
                        <div></div>
                    </div>
                </>
            ) : (
                <>
                    <div id="addimglb" className="row">Imagem</div>
                    <div id="addimgbx" className="box">
                        <div id="componente">
                            <img src={image} alt="Imagem" style={{ maxWidth: '100%', height: 'auto' }} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Carousel2;