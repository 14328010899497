import React, {useState, useEffect} from 'react';
import AuthState from '../../auth/Auth';
import authenticate from '../../auth/AuthPromise';
import {Redirect} from 'react-router-dom';
import { Button, Grid, Link } from '@material-ui/core';
import { Field, reduxForm, change } from 'redux-form';
import { renderTextField, renderSelectSearchField, renderSelectField } from '../Fields/Fields';
import { cpfMask } from '../../services/masks';
import api from '../../services/api';
import { ToastMessage } from '../../layouts';
import useStyles from './loginFormStyle';
import validate from './loginFormHelper';
import OneSignal from 'react-onesignal';
import apiNotify from '../../services/apiNotify';

import Header from './Components/header';
import Home from './Components/home';
import Descricao from './Components/descricao';
import Login from './Components/login';
import Rodape from './Components/rodape';
import RedeSocias from './Components/redesocias';
import Noticias from './Components/noticias';

let LoginForm = (props) => {
    const { handleSubmit, submitting } = props;
    const [companyList, setCompanyList] = useState([]);
    const [logged, setLogged] = useState(AuthState.getAuthenticated());
    const [isAdmin, setIsAdmin] = useState((AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false)
    const [recaptched, setRecaptched] = useState(false)
    const classes = useStyles();
    const [checkNotificationsEnabled, setCheckNotificationsEnabled] = useState(false)

    useEffect(() => {
        OneSignal.init({appId: "e18ed9d5-d7b9-40ca-ac53-d4a7f7f69bc1"});
        if (!checkNotificationsEnabled){
            setCheckNotificationsEnabled(true);
            OneSignal.isPushNotificationsEnabled().then((resp) =>{
                if (!resp) {
                    ToastMessage.warning('Favor click no sininho a baixo para receber as notificações!');
                } 
            });
        }

      })
    

    const login = (values) => {


        if (values && values.cpf && values.password && values.companyId) {
            return authenticate(values)
            .then(user => {
                setIsAdmin(user.data.profile.isAdmin);
                console.log(user.data)
                setLogged(true) 
                
                OneSignal.getUserId().then((playerId) => {
                    if(playerId){
                        apiNotify.put('/users',{userId:user.data.user.id, playerId:playerId}).then(
                            (res) => {console.log(res)}
                        )
                    } else {
                            OneSignal.getSubscription()
                            .then((isSubscripted) => {
                                if (isSubscripted) {
                                    OneSignal.getUserId().then((playerId) => {
                                        if (playerId){
                                            apiNotify.put('/users',{userId:user.data.user.id, playerId:playerId}).then(
                                                (res) => {console.log(res)}
                                            )
                                        } else {
                                            ToastMessage.warning('Favor click no sininho a baixo para receber as notificações!');
                                        }
                                        
                                    })
                                } else {
                                    ToastMessage.warning('Favor click no sininho a baixo para receber as notificações!');
                                }
                                
                            })
                    }
                })
               
                ToastMessage.success('Você está logado!');
            })
            .catch(error => {
                setLogged(false)
                setIsAdmin(false);
                ToastMessage.error('Usuário ou senha inválida');
            })
        }
    }

    const onChange = (value) => {
        setRecaptched(true);
    }

    const onExpired = (value) => {
        setRecaptched(false);
    }

    // const getSecretariasList = () => {

    //     if (!loadingSecretaria) {
    //         setLoadingSecretaria(true);
    //         api.get('/users/contato?pageSize=-1').then(list => {
    //             setSecretariaList(list.data.rows);                
    //         });
    //     }
    // }      

    const getCompanyList = (e) => {
        const cpf = e.target.value.replaceAll('-', '').replaceAll('.', '');

        // api.get(`public/companies/`).then(list => {
        api.get(`public/companies/meu/${cpf}?pageSize=-1`).then(list => {            
            setCompanyList(list.data.rows);
        });
    }

    const createList = () => {
        let list = []
        let LConpanyId = '';
        if (companyList && companyList.length > 0) {
            
            companyList.forEach((entity) => {
                // list.push({name: entity.company.name, value: entity.company.id})
                if ((list.length === 0)) {
                    LConpanyId = entity.company.id;                    
                }
                list.push(<option key={entity.company.id} value={entity.company.id}>{entity.company.name}</option>);
            })
        }
        // console.log(list);
        props.dispatch(change('LoginForm', 'companyId', LConpanyId));
        return list;
    }

    // useEffect(() => getCompanyList(), []);
    
    if (logged) {
        if (isAdmin) {
            return <Redirect from="/login" to="/home"/>
        } else {
            return <Redirect from="/login" to="/home"/>
        }        
    } else {
        return (
            
            <Grid container spacing={3} wrap="wrap">
                <Grid item xs={12}>
                <form name="loginForm" onSubmit={handleSubmit(login)}>
                    <div className={classes.mainForm}>

                                <Grid item xs={12}>
                                    <div id="login">
                                        <Login/>
                                    </div>  
                                </Grid>  
                    </div>
                </form>
                </Grid>

            </Grid>
            
        )
    }
}

LoginForm = reduxForm({
    form: 'LoginForm',
    validate
})(LoginForm);

export default LoginForm;
