// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-shading-mui {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    background: rgba(255, 255, 255, .3);
  }
  
  .loading-icon-mui {
    position: absolute;
    font-size: 20px;
    top: calc(45% - 10px);
    left: calc(50% - 10px);
  }`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;;IAEZ,mCAAmC;EACrC;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,sBAAsB;EACxB","sourcesContent":[".loading-shading-mui {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  \n    background: rgba(255, 255, 255, .3);\n  }\n  \n  .loading-icon-mui {\n    position: absolute;\n    font-size: 20px;\n    top: calc(45% - 10px);\n    left: calc(50% - 10px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
