import React from 'react';
import './styles/redesocias.css'

import fotorede from "./assets/RedesSociais.webp"
import {BsFacebook} from 'react-icons/bs';
import {BsInstagram} from 'react-icons/bs';
import {BsYoutube} from 'react-icons/bs';
import onda from "./assets/onda_invertida.webp"

function RedeSocias  () {
    return(
    <section id="rede-Conteudo">
        <div id="onda">
            <img src={onda} alt="onda"/>
        </div>
        <div id="conteudo-rede">
        <nav id="redes-sociais">
            <h1 id="titulo-social">Redes Sociais</h1>

            <div id="img-social">

                <div id="img-social-elementos">
                    <a href="https://www.facebook.com" id="face"target="_blank" rel="noreferrer">
                            <h3 alt= 'page1'><BsFacebook
                            style={{color :'#fff'}}/></h3>
                    </a>
                </div>


                <div id="img-social-elementos">
                    <a href="https://www.youtube.com" id="youtu"target="_blank"rel="noreferrer">
                    <h3 alt= 'page2'><BsYoutube
                    style={{color :'#fff'}}/></h3>
                    </a>
                </div>



                <div id="img-social-elementos">
                    <a href="https://www.instagram.com/mersinho_lucena/" id="instra" target="_blank"rel="noreferrer">
                    <h3 alt= 'page1'><BsInstagram
                    style={{color :'#fff'}}/></h3>
                    </a>
                </div>

            </div>
        </nav>

        <div id="imagem-redeSocial">
            <img src={fotorede} alt=""/>
        </div>

        </div>
    </section>
        )
}
export default RedeSocias;
