// actions/gridListActions.js

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Tipos e Criadores de Ações */
const { Types, Creators } = createActions({
  setGridData: ["value"],
});

export const GridListTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
  gridData: {},
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_GRID_DATA]: (state, { value }) => state.merge({ gridData: value }),
});
