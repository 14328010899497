import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { Button, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    renderTextField,
    renderSelectField,
    renderDatePicker,
    renderImage,
} from '../../components/Fields/Fields';
import BasicForm from '../../components/BasicForm';
import api from '../../services/api';
import { useParams, useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { phoneMask, DateMaskDDMMYYYY, cepMask, cpfMask2 } from '../../services/masks';
import { syncData } from '../../db';
import apiCEP from '../../services/apiCEP';
import { ToastMessage } from '../../layouts';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
    video: {
        display: 'none',
        width: '100%',
        maxWidth: '640px',
        height: 'auto',
    },
    canvas: {
        display: 'none',
        width: '100%',
        maxWidth: '640px',
        height: 'auto',
    },
}));

const formName = 'pesquisa';
const modelName = 'pesquisa';
const label = 'Visita';

const Form = (props) => {
    const propLocation = useLocation();
    const classes = useStyles();
    const [responsavelList, setResponsavelList] = useState([]);
    const [bairroList, setBairroList] = useState([]);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [cameraActive, setCameraActive] = useState(false);
    const [showOpenCameraButton, setShowOpenCameraButton] = useState(true);
    const [location, setLocation] = useState(null);
    const { userId } = useParams();

    useEffect(() => {
        getBairroList();
        setUserOwner();
    }, []);

    useEffect(() => {
        const authData = JSON.parse(sessionStorage.getItem('auth'));
        if (authData && authData.userInfo && authData.userInfo.id) {
            const id = authData.userInfo.id;
            props.dispatch(change(formName, 'ownerId', id));
        }
    }, [props]);

    const getDataByCEP = (event) => {
        const numberPattern = /\d+/g;
        let cep = (event.target.value.match( numberPattern ) || []).join("");

        if (cep) {
            apiCEP.get(`/ws/${cep}/json`)
            .then(data => {
                const values = data.data;
                props.dispatch(change(formName, 'address', values.logradouro));
                props.dispatch(change(formName, 'neighborhood', values.bairro));
            })
            .catch(error => {
                console.error(error);
                ToastMessage.error('CEP não encontrado');
            })
        }
    }


    const getBairroList = async () => {
        try {
          // Tente obter os dados do cache primeiro
          const cache = await caches.open('bairro-cache');
          const cachedResponse = await cache.match('bairro-list');
      
          if (cachedResponse) {
            const data = await cachedResponse.json();
            setBairroList(data);
          } else {
            // Se não houver dados no cache, faça a chamada à API
            const response = await api.get('/bairro?pageSize=-1');
            const data = response.data.rows;
            setBairroList(data);
            
            // Salve os dados no cache
            const responseToCache = new Response(JSON.stringify(data));
            await cache.put('bairro-list', responseToCache);
          }
        } catch (error) {
          console.error('Failed to fetch or cache bairro list:', error);
        }
      };
      

    const renderSelection = (list) => (
        list && list.length > 0 && list.map(entity => (
            <option key={entity.id} value={entity.id}>{entity.titulo || entity.nome || entity.name}</option>
        ))
    );
    
    const setUserOwner = () => {
        if (userId) {
            props.dispatch(change(formName, 'ownerId', userId));
        }
    };

    const renderSelection2 = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'} value={''}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.name}>{entity.name}</option>);
            });
            return finalList;
        }
    }
    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
                    props.dispatch(change(formName, 'latitude', latitude));
                    props.dispatch(change(formName, 'longitude', longitude));
                },
                (error) => {
                    console.error('Error getting location:', error);
                },
                { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleStartRecording = () => {
        const constraints = {
            video: {
                width: { ideal: 640 },
                height: { ideal: 480 },
                facingMode: "enviroment"
            }
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                setStream(stream);
                const video = videoRef.current;
                video.style.display = 'block';
                video.srcObject = stream;
                video.play();
                setCameraActive(true);
                setShowOpenCameraButton(false);
            })
            .catch((error) => {
                console.error('Error accessing camera:', error);
            });
    };

    const handleCapturePhoto = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const photoData = canvas.toDataURL('image/jpeg', 0.5);
        props.dispatch(change(formName, 'photo', photoData));
        video.style.display = 'none';
        canvas.style.display = 'block';
        stream.getTracks().forEach(track => track.stop());
        setCameraActive(false);
        setShowOpenCameraButton(true);
    };

    return (
        <>
        <BasicForm
            keepUpdateAfterSubmit={true}
            imageContext={'visita'}
            routeAfterSubmit="/registroVisitaList"
            maxImages={1}         
            
            content={
                <Paper className={classes.formContainer}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <img
                                    src={props.photo}
                                    alt="Captured"
                                    style={{ display: props.photo ? 'block' : 'none', maxWidth: '100%', marginTop: '16px' }}
                                />
                                <canvas ref={canvasRef} className={classes.canvas} />
                            </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="name" component={renderTextField} label="Nome" />
                        </Grid>
                        
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="cpf" component={renderTextField} label="CPF" {...cpfMask2} />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="cep" component={renderTextField} onBlur={getDataByCEP} label="CEP"{...cepMask} {...{required: true}}/>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="address" component={renderTextField} label="Rua" />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="number" component={renderTextField} label="Numero" />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="neighborhood" component={renderTextField} disabled={navigator.onLine ? true : false}label="Bairro" style={{ maxWidth: '100%', width: 350 }}>
                                {renderSelection2(bairroList)}
                            </Field>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="phone" component={renderTextField} label="Telefone"{...phoneMask} />
                        </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Field name="birthDate" type="date" component={renderDatePicker} label="Data" normalize={value => value && value.toUpperCase()} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="statusProfissional" component={renderSelectField} label="Status Profissional">
                                <option value={'Empregado'}>Empregado</option>
                                <option value={'Desempregado'}>Desempregado</option>
                                <option value={'Estudante'}>Estudante</option>
                            </Field>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="tipo_voto" component={renderSelectField} label="Tipo Voto">
                                <option value={'S'}>A favor</option>
                                <option value={'I'}>Indeciso</option>
                                <option value={'N'}>Contra</option>
                            </Field>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="vote" component={renderTextField} label="Candidato" />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="hierarquia_familiar" component={renderTextField} label="Hierarquia familiar" />
                        </Grid>
                       {propLocation.pathname === '/registroVisita' && ( <Grid item xs={isMobile ? 12 : 6}>
                            <Button className={classes.button} type="button" color="primary" variant="contained" onClick={handleGetLocation}>
                                Pegar Localização
                            </Button>
                            {/* {location && (
                                <Typography variant="body1" color="textSecondary">
                                    Latitude: {location.latitude}, Longitude: {location.longitude}
                                </Typography>
                            )} */}
                        </Grid>)}
                        {propLocation.pathname === '/registroVisita' && (<Grid item xs={isMobile ? 12 : 6}>
                            {showOpenCameraButton ? (
                                <Button className={classes.button} type="button" color="primary" variant="contained" onClick={handleStartRecording}>
                                    Abrir Camera
                                </Button>
                            ) : (
                                <Button className={classes.button} type="button" color="primary" variant="contained" onClick={handleCapturePhoto}>
                                    Tirar Foto
                                </Button>
                            )}
                            <video ref={videoRef} className={classes.video} />
                            <canvas ref={canvasRef} className={classes.canvas} />
                        </Grid>)}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="city" component={renderTextField} label="Cidade" style={{ display: 'none' }} />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="state" component={renderTextField} label="Estado" style={{ display: 'none' }} />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="latitude" component={renderTextField} disabled label="Latitude" style={{ display: 'block' }} />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Field name="longitude" component={renderTextField} disabled label="Longitude" style={{ display: 'block' }} />
                        </Grid>

                    </Grid>
                </Paper>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            {...props}
            />
        <Button onClick={syncData} label="Teste"/>
        </>
    );
};

export default reduxForm({
    form: formName,
    initialValues: {
        city: 'João Pessoa',
        state: 'PB',
        tipo_voto: 'S',
        statusProfissional: 'Empregado',
    }
})(Form);
