import React, { useState, useEffect } from "react";
import "./styles/login.css";

import AuthState from "../../../auth/Auth";
import authenticate from "../../../auth/AuthPromise";
import { Redirect } from "react-router-dom";
import { Button, Grid, Link } from "@material-ui/core";
import { Field, reduxForm, change } from "redux-form";
import { renderTextField, renderSelectField } from "../../Fields/Fields";
import { cpfMask } from "../../../services/masks";
import api from "../../../services/api";

import { ToastMessage } from "../../../layouts";
import useStyles from "./loginFormStyle";
import validate from "./loginFormHelper";
import ReCAPTCHA from "react-google-recaptcha";
import OneSignal from "react-onesignal";
import apiNotify from "../../../services/apiNotify";

function LoginFm(props) {
  const { handleSubmit, submitting } = props;
  const [companyList, setCompanyList] = useState([]);
  const [logged, setLogged] = useState(AuthState.getAuthenticated());
  const [isAdmin, setIsAdmin] = useState(
    (AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false
  );
  const [recaptched, setRecaptched] = useState(false);
  const classes = useStyles();
  const [checkNotificationsEnabled, setCheckNotificationsEnabled] =
    useState(false);

  useEffect(() => {
    OneSignal.init({ appId: "e18ed9d5-d7b9-40ca-ac53-d4a7f7f69bc1" });
    if (!checkNotificationsEnabled) {
      setCheckNotificationsEnabled(true);
      OneSignal.isPushNotificationsEnabled().then((resp) => {
        if (!resp) {
          ToastMessage.warning(
            "Favor click no sininho a baixo para receber as notificações!"
          );
        }
      });
    }
  });

  const login = (values) => {
    if (values && values.cpf && values.password && values.companyId) {
      return authenticate(values)
        .then((user) => {
          setIsAdmin(user.data.profile.isAdmin);
          setLogged(true);

          OneSignal.getUserId().then((playerId) => {
            if (playerId) {
              apiNotify
                .put("/users", {
                  userId: user.data.user.id,
                  playerId: playerId,
                })
                .then((res) => {
                  console.log(res);
                });
            } else {
              OneSignal.getSubscription().then((isSubscripted) => {
                if (isSubscripted) {
                  OneSignal.getUserId().then((playerId) => {
                    if (playerId) {
                      apiNotify
                        .put("/users", {
                          userId: user.data.user.id,
                          playerId: playerId,
                        })
                        .then((res) => {
                          console.log(res);
                        });
                    } else {
                      ToastMessage.warning(
                        "Favor click no sininho a baixo para receber as notificações!"
                      );
                    }
                  });
                } else {
                  ToastMessage.warning(
                    "Favor click no sininho a baixo para receber as notificações!"
                  );
                }
              });
            }
          });

          ToastMessage.success("Você está logado!");
        })
        .catch((error) => {
          setLogged(false);
          setIsAdmin(false);
          ToastMessage.error("Usuário ou senha inválida");
        });
    }
  };

  const onChange = (value) => {
    setRecaptched(true);
  };

  const onExpired = (value) => {
    setRecaptched(false);
  };

  const getCompanyList = (e) => {
    const cpf = e.target.value.replaceAll("-", "").replaceAll(".", "");

    // api.get(`public/companies/`).then(list => {
    api.get(`public/companies/meu/${cpf}?pageSize=-1`).then((list) => {
      setCompanyList(list.data.rows);
    });
  };

  const createList = () => {
    let list = [];
    let LConpanyId = "";
    if (companyList && companyList.length > 0) {
      companyList.forEach((entity) => {
        if (list.length === 0) {
          LConpanyId = entity.company.id;
        }
        list.push(
          <option key={entity.company.id} value={entity.company.id}>
            {entity.company.name}
          </option>
        );
      });
    }
    props.dispatch(change("LoginFm", "companyId", LConpanyId));
    return list;
  };

  if (logged) {
    if (isAdmin) {
      return <Redirect from="/login" to="/home" />;
    } else {
      return <Redirect from="/login" to="/home" />;
    }
  } else {
    return (
      <section>
        <form name="loginFm" onSubmit={handleSubmit(login)}>
          <div id="login-main">
            <div id="loginsup">
              <h3>Login</h3>
            </div>

            <Grid item xs={10}>
              <Field
                name="cpf"
                type="text"
                className={classes.loginInput}
                style={{ maxWidth: "100%", width: 250, marginTop: 10 }}
                onBlur={getCompanyList}
                component={renderTextField}
                label="Login"
                {...cpfMask()}
              />
            </Grid>

            <Grid item xs={10}>
              <Field
                name="password"
                type="password"
                className={classes.passwordInput}
                sx={{ borderRadius: "30px" }}
                style={{ maxWidth: "100%", width: 250, marginTop: 10 }}
                component={renderTextField}
                label="Senha"
              />
            </Grid>

            {companyList.length > 0 && (
              <Grid item xs={10}>
                <Field
                  name="companyId"
                  component={renderSelectField}
                  label="Empresa"
                  style={{ maxWidth: "100%", width: 250, marginTop: 10 }}
                >
                  {createList()}
                </Field>
              </Grid>
            )}

            <Grid item xs={10} style={{ margin: 10 }}>
              Esqueceu sua senha?{" "}
              <Link href="/forgotPassword" className={classes.forgotPassword}>
                Clique aqui!
              </Link>
            </Grid>

            {/* <ReCAPTCHA
                            sitekey={process.env.SITE_KEY || "6LfqBaMaAAAAAF_GDt8y8EBxjZYIJiLfYsludp2j"}
                            onChange={onChange}
                            onExpired={onExpired}
                            className={classes.recaptchabox}
                        /> */}
            {/* <Button disabled={submitting || !recaptched} type="submit" style={{ borderRadius: 20, width:130, maxWidth: "100%", marginTop:15 }} color="primary" variant="contained"> Login </Button> */}
            <Button
              disabled={submitting}
              type="submit"
              style={{
                borderRadius: 20,
                width: 130,
                maxWidth: "100%",
                marginTop: 15,
              }}
              color="primary"
              variant="contained"
            >
              {" "}
              Login{" "}
            </Button>
          </div>
        </form>
      </section>
    );
  }
}

LoginFm = reduxForm({
  form: "LoginFm",
  validate,
})(LoginFm);

export default LoginFm;
