import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, change } from "redux-form";
import { Button, Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  renderTextField,
  renderSelectField,
  renderDatePicker,
} from "../../components/Fields/Fields";
import BasicForm from "../../components/BasicForm";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  phoneMask,
  DateMaskDDMMYYYY,
  cepMask,
  cpfMask2,
} from "../../services/masks";
import { syncData } from "../../db";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  video: {
    display: "none",
    width: "100%",
    maxWidth: "640px",
    height: "auto",
  },
  canvas: {
    display: "none",
    width: "100%",
    maxWidth: "640px",
    height: "auto",
  },
}));

const formName = "bairro/eventos";
const modelName = "bairro/eventos";
const label = "Relatorio";

const Form = (props) => {
  const classes = useStyles();
  const [responsavelList, setResponsavelList] = useState([]);
  const [bairroList, setBairroList] = useState([]);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [showOpenCameraButton, setShowOpenCameraButton] = useState(true);
  const [location, setLocation] = useState(null);
  const { userId } = useParams();

  useEffect(() => {
    getResponsaveisList();
    // getBairroList();
    setUserOwner();
  }, []);

  useEffect(() => {
    const authData = JSON.parse(sessionStorage.getItem("auth"));
    if (authData && authData.userInfo && authData.userInfo.id) {
      const id = authData.userInfo.id;
      props.dispatch(change(formName, "ownerId", id));
    }
  }, [props]);

  const getResponsaveisList = () => {
    api.get("/users/responsavel?pageSize=-1").then((list) => {
      const result = list.data.rows.map((item) => item.user);
      setResponsavelList(result);
    });
  };

  // const getBairroList = () => {
  //     api.get('/bairro?pageSize=-1').then(list => {
  //         setBairroList(list.data.rows);
  //     });
  // };

  const renderSelection = (list) =>
    list &&
    list.length > 0 &&
    list.map((entity) => (
      <option key={entity.id} value={entity.id}>
        {entity.titulo || entity.nome || entity.name}
      </option>
    ));

  const setUserOwner = () => {
    if (userId) {
      props.dispatch(change(formName, "ownerId", userId));
    }
  };

  const renderSelection2 = (list) => {
    if (list && list.length > 0) {
      let finalList = [<option key={"none"} value={""}></option>];
      list.forEach((entity) => {
        finalList.push(
          <option key={entity.id} value={entity.name}>
            {entity.name}
          </option>
        );
      });
      return finalList;
    }
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          props.dispatch(change(formName, "latitude", latitude));
          props.dispatch(change(formName, "longitude", longitude));
        },
        (error) => {
          console.error("Error getting location:", error);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <BasicForm
        keepUpdateAfterSubmit={true}
        imageContext={"registroAtividadesCfg"}
        routeAfterSubmit="/relatorioTipo"
        maxImages={5}
        content={
          <Paper className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field name="data" component={renderTextField} label="Data" />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field
                  name="descricao"
                  component={renderTextField}
                  label="Descrição"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field
                  name="titulo"
                  component={renderTextField}
                  label="Titulo"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field
                  name="$solicitante.name$"
                  component={renderTextField}
                  label="Empresa/Orgão"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field name="tipo" component={renderSelectField} label="Tipo">
                  <option value={"Saude"}>Saúde</option>
                  <option value={"Infraestrutura"}>Infraestrutura</option>
                  <option value={"Governança"}>Governança</option>
                  <option value={"Evento"}>Evento</option>
                </Field>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field name="address" component={renderTextField} label="Rua" />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field
                  name="latitude"
                  component={renderTextField}
                  label="Latitude"
                  style={{ display: "none" }}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field
                  name="longitude"
                  component={renderTextField}
                  label="Longitude"
                  style={{ display: "none" }}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Field
                  name="cep"
                  component={renderTextField}
                  label="CEP"
                  {...cepMask}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Button
                  className={classes.button}
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleGetLocation}
                >
                  Pegar Localização
                </Button>
                {location && (
                  <Typography variant="body1" color="textSecondary">
                    Latitude: {location.latitude}, Longitude:{" "}
                    {location.longitude}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>
        }
        formName={formName}
        modelName={modelName}
        label={label}
        {...props}
      />
      {/* <Button onClick={syncData} label="Teste"/> */}
    </>
  );
};

export default reduxForm({
  form: formName,
  initialValues: {
    city: "João Pessoa",
    state: "PB",
  },
})(Form);
